import { Grid, Modal, Paper, Stack, Switch } from "@mui/material";
import chroma from "chroma-js";
import ReactECharts from "echarts-for-react";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { isEqual, reverse } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import { CSVLink } from "react-csv";
import { FaMapLocationDot } from "react-icons/fa6";
import { GiHistogram } from "react-icons/gi";
import { IoBarChartOutline, IoPieChartOutline } from "react-icons/io5";
import { LiaChartAreaSolid, LiaPlayCircleSolid } from "react-icons/lia";
import { PiChartDonutThin, PiChartLineLight } from "react-icons/pi";
import { TbChartHistogram, TbSum } from "react-icons/tb";
import { TfiLayoutGrid4Alt } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";

import {
  Legend,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import { ButtonNew } from "../../components/ButtonNew";
import { chartTypesEnums, diagramTypes, valuesEnums } from "../../enums/dynamicDashboard";
import { setDataToDownload } from "../../features/energyPerformance/energyPerformanceSliceNew";
import { distinctFilter, findDataRange, isNumeric, keepKeysFromObjects } from "../../utils/dataManipulation";
import { getWeekdayNumber } from "../../utils/date";
import { aggregatedSumValue, diagramInitialConfig, generateAxisValues, getAxisNameWithUnit, getColumnDisplayName, getDataKeyFromDiagramFilterName, getMapPointColor, getArrayLongestElement, getSeriesMixedName, pivotData, settingsOptions, sortData, sortDataAccumulative } from "../../utils/dynamicDashboard";
import { formatNumberBasedOnUser } from "../../utils/namesManipulation";
import { DynamicFilterFactory } from "../DynamicFormInput";
import { EmptyErrorBoundary, TextErrorBoundary } from "../ErrorBoundary";
import { Icon } from "../Icon";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";
import { MyMap } from "../map/Map";
import { ColorBar } from "./Colorbar";
import LegendItem from "./LegendItem";
import { useTranslation } from "react-i18next";
import * as Comlink from 'comlink';
import { FaCircleNotch, FaBrush } from 'react-icons/fa';
// import JSONfn from 'json-fn';


const useDynamicDashboardCalculations = () => {
  const [isInTransformingAndAggregating, setIsInTransformingAndAggregating] = useState(false)
  const [isAggregating, setIsAggregating] = useState(false)
  const [isTransforming, setIsTransforming] = useState(false)
  const [updatedOption, setUpdatedOption] = useState({})
  const [isPainting, setIsPainting] = useState(false)

  const isProcessing = isInTransformingAndAggregating || isAggregating || isTransforming

  const dataTransformatorDecorator = async (dataTransformator) => {
    try {
      setIsInTransformingAndAggregating(true)
      setIsPainting(false)
      setIsTransforming(true)
      await dataTransformator()
    } finally {
      setIsTransforming(false)
    }
  }


  const dataAggregatorDecorator = async (dataAggregater, worker) => {
    try {
      setIsPainting(false)
      setIsAggregating(true)
      await dataAggregater()
    } finally {
      setIsAggregating(false)
      worker.terminate();
    }
  }

  const setOption = (newOptions) => {
    if (!isAggregating && !isTransforming) {
      setIsPainting(true)
      setIsInTransformingAndAggregating(false)
      setUpdatedOption(newOptions)
    }
  }


  return {
    setIsProcessing: setIsInTransformingAndAggregating, isAggregating,
    setIsAggregating, isTransforming, setIsTransforming,
    isProcessing, dataTransformatorDecorator, dataAggregatorDecorator, isPainting, setIsPainting, option: updatedOption, setOption
  }
}


const DynamicComboChartMultithread = ({ xDataKey, isConfigSetting, ...props }) => {
  const dispatch = useDispatch()
  const {
    title,
    id,
    data: withoutIndexData,
    chartsConfigs,
    sort,
    legendsPosition,
    isFullScreen,
    getUnit,
    getColor,
    config,
    onLegendSelect,
    onHover,
    onMouseOut,
    isItemHovered,
    somethingIsHovered,
    getColumnDisplayName,
    getColumnSortFunction,
    hoverEffect,
    getDataTransformatorMemoDependencyArray,
    dataTransformator,
    dataTransformatorMultithread,
  } = props
  const { invertXAxis, invertYAxis, diagrams, aggregateXAxis } = config
  const selectedConfigs = chartsConfigs?.filter((config) => config.dataKey && config.diagramType)

  const diagramsFiltersNames = Object.keys(diagrams?.[0])?.filter(key => key.startsWith(valuesEnums.DIAGRAM_FILTER_PREFIX))
  const diagramsActiveFiltersNames = diagramsFiltersNames?.filter(col => selectedConfigs?.some(conf => conf[col] !== undefined))

  const diagramsFiltersDataKeys = diagramsActiveFiltersNames?.map(filterName => getDataKeyFromDiagramFilterName({ filterName }))
  const diagramsSegments = diagramsFiltersDataKeys?.filter(distinctFilter) || []

  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({ config })

  const [flattenData, setFlattenData] = useState([])

  const { setIsPainting,
    isProcessing, dataTransformatorDecorator, dataAggregatorDecorator,
    isPainting, option, setOption
  } = useDynamicDashboardCalculations()

  const activeWorkerRef = useRef(null)
  const workerId = useRef(0)
  useEffect(() => {
    if (activeWorkerRef.current?.terminate) {
      activeWorkerRef.current.terminate();
    }

    workerId.current += 1

    const workerInstance = dataTransformatorMultithread()
    activeWorkerRef.current = workerInstance;
    const getTransformedData = async () => {
      const { flattenData: newFlattenData, requestId } = await workerInstance.calculate(withoutIndexData, { ...config }, workerId.current)
      if (requestId === workerId.current) {
        setFlattenData(newFlattenData)
      }
    }

    if (!withoutIndexData?.length || isConfigSetting) return
    dataTransformatorDecorator(getTransformedData)

    return () => {
      if (workerInstance.terminate) {
        workerInstance.terminate();
      }
    }
  }, [JSON.stringify(dataTransformatorDependency), withoutIndexData, isConfigSetting])



  const xIsNumeric = flattenData?.some(row => isNumeric(row[xDataKey]))

  const aggregate = aggregateXAxis === undefined ? !xIsNumeric : aggregateXAxis

  const [allAggregatedData, SetAllAggregatedData] = useState([])
  const [sortedData, setSortedData] = useState([])
  const [xAxisValues, SetXAxisValues] = useState([])
  const [data, setData] = useState([])

  const sortAggregatedDataOnX = (aggregatedData) => {
    const sorted = aggregatedData?.toSorted((a, b) => getColumnSortFunction({ dataKey: xDataKey, aValue: a[xDataKey], bValue: b[xDataKey] }))
    return sorted
  }

  const activeAggregaterWorkerRef = useRef(null)
  const aggregaterWorkerId = useRef(0)
  useEffect(() => {
    if (activeAggregaterWorkerRef.current) {
      activeAggregaterWorkerRef.current?.terminate();
    }

    aggregaterWorkerId.current += 1

    const worker = new Worker(new URL('../../../src/comboChart.worker.js', import.meta.url));
    activeAggregaterWorkerRef.current = worker;

    const runWorker = async (...props) => {
      const comboChartDataAggregater = Comlink.wrap(worker);
      const result = await comboChartDataAggregater(...props);
      return result
    }

    const aggregater = async () => {
      let { aggregatedData, sortedData: sortedDataTemp, data: dataTemp, xAxisValues: xAxisValuesTemp, requestId } = await runWorker({
        flattenData, sort, xDataKey,
        aggregate, xIsNumeric,
        aggregateColumns: selectedConfigs?.map((config) => config.dataKey)?.filter(distinctFilter),
        groupByColumns: [xDataKey, ...diagramsSegments],
        dataKeys: selectedConfigs?.map(config => config.dataKey)
      }, getColumnSortFunction ? Comlink.proxy(sortAggregatedDataOnX) : undefined, aggregaterWorkerId.current)

      if (requestId === aggregaterWorkerId.current) {
        SetAllAggregatedData(aggregatedData)
        setSortedData(sortedDataTemp)
        SetXAxisValues(xAxisValuesTemp)
        setData(dataTemp)
      }
    }

    if (!flattenData?.length || isConfigSetting) return
    dataAggregatorDecorator(aggregater, worker)

    return () => {
      worker.terminate();
    }
  },
    [flattenData, xDataKey, sort, JSON.stringify(selectedConfigs), isConfigSetting]
    // [partitions, stacks, sort, flattenData]
  )

  const xIsCategory = selectedConfigs?.some(selectedConfig => selectedConfig.diagramType === diagramTypes.BAR)

  let xAxis = xIsNumeric ? {
    inverse: invertXAxis,
    type: xIsCategory ? 'category' : 'value'
  } :
    {
      data: xAxisValues,
      axisPointer: {
        type: "shadow",
      },
      inverse: invertXAxis
    }

  const xUnit = getUnit({ quantity: xDataKey, config })
  const xAxisName = getAxisNameWithUnit({ dataKey: getColumnDisplayName({ colName: xDataKey }), unit: xUnit })
  xAxis = [{ ...xAxis, nameGap: 25, nameLocation: 'center', name: xAxisName }]


  let yAxis = xIsNumeric ? [{
    inverse: invertYAxis
  }] : [
    {
      type: "value",
      inverse: invertYAxis
    },
  ]

  const yAxisUnits = selectedConfigs.map(config => getAxisNameWithUnit({ unit: getUnit({ quantity: config.dataKey, config }) }))
  const yAxisUnitUnique = yAxisUnits.filter(distinctFilter)
  const yAxisUnitText = yAxisUnitUnique.join(' - ')
  yAxis = [{ ...yAxis[0], name: yAxisUnitText }]

  useEffect(() => {
    if (isConfigSetting || !flattenData?.length) return
    const newOptions = {
      xAxis,
      yAxis,
      series: selectedConfigs?.map((selectedConfig, i) => {
        const activeFiltersNames = diagramsFiltersNames?.filter(col => selectedConfig[col] !== undefined)
        const activeFiltersNamesAndValues = activeFiltersNames?.map(col => [col, selectedConfig[col]])

        const seriesMixedName = getSeriesMixedName({ seriesName: selectedConfig.dataKey, filters: activeFiltersNamesAndValues })
        const color = getColor && getColor({ quantity: seriesMixedName, chartType: config?.chartType, diagrams, })
        const seriesRelatedData = sortedData?.filter(row => {
          let output = true
          activeFiltersNames?.forEach((filterName, index) => {
            const selectedValue = selectedConfig[filterName]

            const dataColName = diagramsFiltersDataKeys[index]
            const rowValue = row[dataColName]
            if (selectedValue !== rowValue)
              output = false
          })
          return output
        })
        let seriesAggregatedData = !aggregate ? seriesRelatedData : aggregatedSumValue({
          data: seriesRelatedData,
          aggregateColumns: [selectedConfig.dataKey],
          groupByColumns: [xDataKey],
        })

        return {
          name: seriesMixedName,
          type: selectedConfig.diagramType,
          color: color ?? getItemColor(i),
          large: true,
          largeThreshold: 1000,
          progressive: 500,
          progressiveThreshold: 1000,

          tooltip: {
            trigger: 'item',
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          // data: xIsNumeric ? sortedData?.map((row, index) => ({
          //   value: [row[xDataKey], row[selectedConfig?.dataKey]],
          //   itemStyle: {
          //     opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
          //       row, selectedConfig, chartType: config?.chartType
          //       , xAxis: config?.xAxis, yAxis: config?.yAxis, diagrams
          //     })) ? 0.05 : 1
          //   },
          //   row
          // })) : sortedData?.map((row) => ({
          //   value: row[selectedConfig?.dataKey],
          //   itemStyle: {
          //     opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
          //       row, selectedConfig, chartType: config?.chartType
          //       , xAxis: config?.xAxis, yAxis: config?.yAxis, diagrams
          //     })) ? 0.05 : 1
          //   },
          //   row
          // }))
          data: seriesAggregatedData?.map((row, index) => ({
            value: [row[xDataKey], row[selectedConfig?.dataKey]],
            itemStyle: {
              opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
                row, selectedConfig, chartType: config?.chartType
                , xAxis: config?.xAxis, yAxis: config?.yAxis, diagrams
              })) ? 0.05 : 1
            },
            row
          })),
          maxValue: Math.max(...seriesAggregatedData?.map(row => row[selectedConfig?.dataKey] ?? 0)),
          emphasis: {
            focus: hoverEffect ? undefined : "series",
          },
        };
      }),
    };
    setOption({ ...newOptions })
  }, [somethingIsHovered, allAggregatedData, JSON.stringify(selectedConfigs),
    diagrams, invertYAxis, sortedData, config, getColor, invertXAxis, isConfigSetting])

  const dataKeysForDownload = selectedConfigs?.map(config => config.dataKey)
  useEffect(() => {
    try {
      if (dataKeysForDownload) {
        dataKeysForDownload?.push(xDataKey)
        let dataToDownload = keepKeysFromObjects(xIsNumeric ? data : allAggregatedData, dataKeysForDownload)
        dispatch(setDataToDownload({ id: id, data: dataToDownload }))
      }
    } catch { }

  }, [JSON.stringify(dataKeysForDownload), data, allAggregatedData, id])

  const longestY = getArrayLongestElement(option.series?.map(s => s.maxValue ?? 0))

  if (isConfigSetting) return
  return <DynamicEchartsChartWithLoader isPainting={isPainting} isProcessing={isProcessing} setIsPainting={setIsPainting}
    option={option} title={title} legendsPosition={legendsPosition} isFullScreen={isFullScreen}
    onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ params, config }) : undefined}
    onHover={onHover ? (params) => onHover({ params, config, point: params?.data?.row }) : undefined}
    onMouseOut={onMouseOut ? (params) => onMouseOut({ params, config }) : undefined}
    getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
    longestY={longestY}
    onFinished={() => {
      setIsPainting(false)
    }} />

};

const DynamicComboChart = ({ xDataKey, ...props }) => {
  const dispatch = useDispatch()
  const {
    title,
    id,
    data: withoutIndexData,
    chartsConfigs,
    sort,
    legendsPosition,
    isFullScreen,
    getUnit,
    getColor,
    config,
    onLegendSelect,
    onHover,
    onMouseOut,
    isItemHovered,
    somethingIsHovered,
    getColumnDisplayName,
    getColumnSortFunction,
    hoverEffect,
    getDataTransformatorMemoDependencyArray,
    dataTransformator
  } = props
  const { invertXAxis, invertYAxis, diagrams, aggregateXAxis } = config
  const selectedConfigs = chartsConfigs?.filter((config) => config.dataKey && config.diagramType)

  const diagramsFiltersNames = Object.keys(diagrams?.[0])?.filter(key => key.startsWith(valuesEnums.DIAGRAM_FILTER_PREFIX))
  const diagramsActiveFiltersNames = diagramsFiltersNames?.filter(col => selectedConfigs?.some(conf => conf[col] !== undefined))

  const diagramsFiltersDataKeys = diagramsActiveFiltersNames?.map(filterName => getDataKeyFromDiagramFilterName({ filterName }))
  const diagramsSegments = diagramsFiltersDataKeys?.filter(distinctFilter) || []

  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({ config })
  let { flattenData } = useMemo(() => dataTransformator(withoutIndexData, { ...config }), [dataTransformatorDependency, withoutIndexData])

  const xIsNumeric = flattenData?.some(row => isNumeric(row[xDataKey]))

  const aggregate = aggregateXAxis === undefined ? !xIsNumeric : aggregateXAxis
  const { aggregatedData, sortedData, data } = useMemo(() => {
    if (!flattenData?.length || !xDataKey) return {};

    const data = flattenData?.map((d, index) => ({
      ...d,
      index,
    }));

    let aggregatedData = !aggregate ? data : aggregatedSumValue({
      data,
      aggregateColumns: selectedConfigs?.map((config) => config.dataKey)?.filter(distinctFilter),
      groupByColumns: [xDataKey, ...diagramsSegments],
    })

    if (sort === 'y') {
      aggregatedData = sortDataAccumulative({ data: aggregatedData, dataKeys: selectedConfigs?.map(config => config.dataKey) })
    }
    else {
      if (getColumnSortFunction)
        aggregatedData.sort((a, b) => getColumnSortFunction({ dataKey: xDataKey, aValue: a[xDataKey], bValue: b[xDataKey] }))
      else
        aggregatedData = sortData({ data: aggregatedData, dataKey: xDataKey })
    }

    // Remove hardcoded value
    const sortedData =
      xDataKey !== "index" || !sort
        ? aggregatedData
        : aggregatedData?.map((d, index) => ({ ...d, index }));

    return { aggregatedData, sortedData, data }
  }, [flattenData, xDataKey, sort, JSON.stringify(selectedConfigs)])

  const xAxisValues = useMemo(() => {
    return !xIsNumeric && sortedData?.map((row) => row[xDataKey]).filter(distinctFilter)
  }, [xDataKey, sortedData]);

  const xIsCategory = selectedConfigs?.some(selectedConfig => selectedConfig.diagramType === diagramTypes.BAR)

  let xAxis = xIsNumeric ? {
    inverse: invertXAxis,
    type: xIsCategory ? 'category' : 'value'
  } :
    {
      data: xAxisValues,
      axisPointer: {
        type: "shadow",
      },
      inverse: invertXAxis
    }

  const xUnit = getUnit({ quantity: xDataKey, config })
  const xAxisName = getAxisNameWithUnit({ dataKey: getColumnDisplayName({ colName: xDataKey }), unit: xUnit })
  xAxis = [{ ...xAxis, nameGap: 25, nameLocation: 'center', name: xAxisName }]


  let yAxis = xIsNumeric ? [{
    inverse: invertYAxis
  }] : [
    {
      type: "value",
      inverse: invertYAxis
    },
  ]

  const yAxisUnits = selectedConfigs.map(config => getAxisNameWithUnit({ unit: getUnit({ quantity: config.dataKey, config }) }))
  const yAxisUnitUnique = yAxisUnits.filter(distinctFilter)
  const yAxisUnitText = yAxisUnitUnique.join(' - ')
  yAxis = [{ ...yAxis[0], name: yAxisUnitText }]

  const option = useMemo(() => {
    return {
      xAxis,
      yAxis,
      series: selectedConfigs?.map((selectedConfig, i) => {
        const activeFiltersNames = diagramsFiltersNames?.filter(col => selectedConfig[col] !== undefined)
        const activeFiltersNamesAndValues = activeFiltersNames?.map(col => [col, selectedConfig[col]])

        const seriesMixedName = getSeriesMixedName({ seriesName: selectedConfig.dataKey, filters: activeFiltersNamesAndValues })
        const color = getColor && getColor({ quantity: seriesMixedName, chartType: config?.chartType, diagrams, })
        const seriesRelatedData = sortedData?.filter(row => {
          let output = true
          activeFiltersNames?.forEach((filterName, index) => {
            const selectedValue = selectedConfig[filterName]

            const dataColName = diagramsFiltersDataKeys[index]
            const rowValue = row[dataColName]
            if (selectedValue !== rowValue)
              output = false
          })
          return output
        })
        let seriesAggregatedData = !aggregate ? seriesRelatedData : aggregatedSumValue({
          data: seriesRelatedData,
          aggregateColumns: [selectedConfig.dataKey],
          groupByColumns: [xDataKey],
        })

        return {
          name: seriesMixedName,
          type: selectedConfig.diagramType,
          color: color ?? getItemColor(i),
          tooltip: {
            trigger: 'item',
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          // data: xIsNumeric ? sortedData?.map((row, index) => ({
          //   value: [row[xDataKey], row[selectedConfig?.dataKey]],
          //   itemStyle: {
          //     opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
          //       row, selectedConfig, chartType: config?.chartType
          //       , xAxis: config?.xAxis, yAxis: config?.yAxis, diagrams
          //     })) ? 0.05 : 1
          //   },
          //   row
          // })) : sortedData?.map((row) => ({
          //   value: row[selectedConfig?.dataKey],
          //   itemStyle: {
          //     opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
          //       row, selectedConfig, chartType: config?.chartType
          //       , xAxis: config?.xAxis, yAxis: config?.yAxis, diagrams
          //     })) ? 0.05 : 1
          //   },
          //   row
          // }))
          data: seriesAggregatedData?.map((row, index) => ({
            value: [row[xDataKey], row[selectedConfig?.dataKey]],
            itemStyle: {
              opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
                row, selectedConfig, chartType: config?.chartType
                , xAxis: config?.xAxis, yAxis: config?.yAxis, diagrams
              })) ? 0.05 : 1
            },
            row
          })),
          maxValue: Math.max(...seriesAggregatedData?.map(row => row[selectedConfig?.dataKey] ?? 0)),
          emphasis: {
            focus: hoverEffect ? undefined : "series",
          },
        };
      }),
    };
  }, [somethingIsHovered, JSON.stringify(selectedConfigs), config?.xAxis, diagrams, sortedData, config, getColor])

  const dataKeysForDownload = selectedConfigs?.map(config => config.dataKey)

  useEffect(() => {
    if (dataKeysForDownload) {
      dataKeysForDownload?.push(xDataKey)
      let dataToDownload = keepKeysFromObjects(xIsNumeric ? data : aggregatedData, dataKeysForDownload)
      dispatch(setDataToDownload({ id: id, data: dataToDownload }))
    }

  }, [JSON.stringify(dataKeysForDownload), data, aggregatedData, id])

  const longestY = getArrayLongestElement(option.series?.map(s => s.maxValue ?? 0))
  return <DynamicEchartsChart option={option} title={title} legendsPosition={legendsPosition} isFullScreen={isFullScreen}
    onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ params, config }) : undefined}
    onHover={onHover ? (params) => onHover({ params, config, point: params?.data?.row }) : undefined}
    onMouseOut={onMouseOut ? (params) => onMouseOut({ params, config }) : undefined}
    getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
    longestY={longestY}
  />;
};

const generateHistData = ({ data, dataKeys, axisValues, filters }) => {
  if (!data?.length || !axisValues?.length) return;
  const histData = [];
  for (const i in axisValues) {
    if (i == 0) continue;
    const min = axisValues[i - 1];
    const max = axisValues[i];
    const label = `${min} - ${max}`;
    const allCounts = {};
    for (const dataKeyIndex in dataKeys) {
      const dataKey = dataKeys[dataKeyIndex]
      const dataKeyFilters = filters[dataKeyIndex]

      const count = data?.filter(row => {
        for (const [filterName, filterValue] of dataKeyFilters) {
          if (row[filterName] !== filterValue && filterValue !== undefined)
            return false
        }
        return true
      })?.reduce((total, current) => {
        const value = current[dataKey];
        if ((value > min || (value === min && i === 1)) && value <= max)
          total++;
        return total;
      }, 0);
      allCounts[dataKey] = count;
    }
    histData.push({ name: label, ...allCounts });
  }
  return histData;
};


const DynamicHistogramChart = (props) => {
  const { data, chartsConfigs, sort, title, legendsPosition,
    isFullScreen, id, config, onLegendSelect, getColor, onHover, onMouseOut, somethingIsHovered, isItemHovered, getColumnDisplayName,
    hoverEffect
  } = props
  const { invertXAxis, invertYAxis } = config


  const diagramsFiltersNames = Object.keys(chartsConfigs?.[0])?.filter(key => key.startsWith(valuesEnums.DIAGRAM_FILTER_PREFIX))

  const dispatch = useDispatch()
  const dataKeys = chartsConfigs?.filter(chartConfig => chartConfig)?.map((chartConfig) => chartConfig.dataKey);
  const dataFilters = chartsConfigs?.filter(chartConfig => chartConfig)?.map(chartConfig => {
    const activeFiltersNames = diagramsFiltersNames?.filter(col => chartConfig[col] !== undefined)
    const activeFiltersDataKeysAndValues = activeFiltersNames?.map(col => [getDataKeyFromDiagramFilterName({ filterName: col }), chartConfig[col]])
    return activeFiltersDataKeysAndValues
  })

  const { histData } = useMemo(() => {
    const minMax = findDataRange({ data, dataKeys });
    const { min, max } = minMax ?? {};
    const axisValues = generateAxisValues(min, max);

    let histData = generateHistData({ data, dataKeys, axisValues, filters: dataFilters });
    if (sort === 'y') {
      histData = sortDataAccumulative({ data: histData, dataKeys })
    }

    return { histData }
  }, [data, JSON.stringify(chartsConfigs), sort])


  var option = useMemo(() => {
    return {
      xAxis: [{
        data: histData?.map((row) => row.name),
        inverse: invertXAxis
      }],
      yAxis: [{
        type: "value",
        name: valuesEnums.COUNT,
        inverse: invertYAxis
      }],
      series: chartsConfigs?.map((chartConfig, i) => {
        const activeFiltersNames = diagramsFiltersNames?.filter(col => chartConfig[col] !== undefined)
        const activeFiltersNamesAndValues = activeFiltersNames?.map(col => [col, chartConfig[col]])
        const seriesMixedName = getSeriesMixedName({ seriesName: chartConfig.dataKey, filters: activeFiltersNamesAndValues })
        const color = getColor && getColor({ quantity: seriesMixedName, chartType: config?.chartType, diagrams: chartsConfigs })
        return {
          name: seriesMixedName,
          type: "bar",
          color: color ?? getItemColor(i),
          stack: i,
          tooltip: {
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          data: histData?.map((row, index) => {
            return {
              value: row[chartConfig?.dataKey],
              itemStyle: {
                opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
                  row: { rangeString: row.name, dataPoint: chartConfig?.dataKey, index, },
                  chartType: config?.chartType, yAxis: config?.yAxis, xAxis: config?.xAxis, diagrams: chartsConfigs
                })) ? 0.05 : 1
              },
              row: { rangeString: row.name, dataPoint: chartConfig?.dataKey, index }
            }
          }),
          maxValue: Math.max(...[histData ?? []]?.map(row => row[chartConfig?.dataKey] ?? 0)),
          emphasis: {
            focus: hoverEffect ? undefined : "series",
          },
        };
      }),
    };

  }, [somethingIsHovered, histData, invertXAxis, invertYAxis, JSON.stringify(chartsConfigs), config?.yAxis, config?.xAxis, getColor])

  useEffect(() => {
    dispatch(setDataToDownload({ id, data: histData?.map((row) => ({ ...row, name: `[${row?.name}]` })) }))
  }, [histData, id])

  return <DynamicEchartsChart option={option} title={title} legendsPosition={legendsPosition} isFullScreen={isFullScreen}
    onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ params, config }) : undefined}
    onHover={onHover ? (params) => onHover({ params, config, point: params?.data?.row }) : undefined}
    onMouseOut={onMouseOut ? (params) => onMouseOut({ params, config }) : undefined}
    getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
    longestY={Math.max(...option.series?.map(s => s.maxValue ?? 0))}
  />;
};

const echartsDefaultTooltipFormatter = (value) => formatNumberBasedOnUser(value)


// const echartsDefaultTooltipNameFormatter = (params, getColumnDisplayName) => {
//   const name = getColumnDisplayName({ colName: !params.dimensionNames?.length ? params.name : params.seriesName })
//   const value = formatNumberBasedOnUser(params.value)
//   return params.marker + ' ' + name + ': ' + value
//   return `
//   ${params.marker} ${params.seriesName}: <br/><strong>${params.name}</strong>
//   Value: <strong>${value}</strong>
// `;
// }

const legendsWidth = 200
const legendsWidthFullScreen = 400

const echartsDefaultOptions = {
  animation: false,
  tooltip: {
    position: "top",
    trigger: "item",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      saveAsImage: { show: true },
    },
  },
  dataZoom: {
    type: "slider",
    height: 16,
    bottom: 30,
  },

};

const legendConfigAtRight = {
  legend: {
    type: "scroll",
    orient: "vertical",
    left: 'right',
    top: 40,
    align: 'right',
    textStyle: {
      width: legendsWidth,
      overflow: 'truncate'
    }
  },
  grid: {
    left: 40,
    right: legendsWidth + 30,
    bottom: 70,
    top: 55,
    containLabel: true,
  },
}

const legendsConfigAtBottom = {
  legend: {
    type: "scroll",
    orient: "horizontal",
    right: 40,
    bottom: 0,
    left: 10,
  },
  grid: {
    left: 40,
    right: 20,
    bottom: 70,
    top: 55,
    containLabel: true,
  },
}


const DynamicEchartsChart = ({ option, ...props }) => {
  const eChartsRef = useRef();

  let mergedOptions = {
    ...echartsDefaultOptions,
    title: {
      text: props.title,
      left: "center",
    },
  };

  if (props?.legendsPosition === 'right') {
    mergedOptions = { ...mergedOptions, ...legendConfigAtRight }
    if (props.isFullScreen) {
      mergedOptions = {
        ...mergedOptions,
        legend: {
          ...mergedOptions.legend,
          textStyle: {
            ...mergedOptions.legend.textStyle,
            width: legendsWidthFullScreen
          }
        },
        grid: {
          ...mergedOptions.grid,
          right: legendsWidthFullScreen + 30,
        },
      }
    }
  } else {
    mergedOptions = { ...mergedOptions, ...legendsConfigAtBottom }
  }

  mergedOptions = {
    ...mergedOptions, legend: {
      ...mergedOptions.legend,
      formatter: (name) => {
        if (props.getLegendDisplayName)
          return props.getLegendDisplayName(name) ?? name
        return name
      }
    },
  }

  if (option) mergedOptions = { ...mergedOptions, ...option, grid: { ...mergedOptions?.grid, ...option?.grid } };

  if (mergedOptions?.yAxis) {
    let maxLabelLength
    if (typeof props?.longestY === 'number' && isNumeric(parseInt(props?.longestY)))
      maxLabelLength = parseInt(props?.longestY)?.toString()?.length
    else if (typeof props?.longestY === 'string') {
      maxLabelLength = props?.longestY?.length
    }
    else {
      maxLabelLength = 5
    }

    // Just a silly formulae for getting a gap for length of a number or text. Make it better if you can
    const nameGap = (maxLabelLength ** 0.4) * 30 + (maxLabelLength ** 0.7) * 4

    const isYInverted = mergedOptions?.yAxis?.[0]?.inverse
    const bottomGrid = mergedOptions?.grid?.bottom
    const topGrid = mergedOptions?.grid?.top
    mergedOptions = {
      ...mergedOptions,
      grid: {
        ...mergedOptions?.grid,
        bottom: isYInverted ? (bottomGrid + 20) : bottomGrid,
        top: isYInverted ? (topGrid - 20) : topGrid,
      },
      yAxis: [{
        // nameGap: mergedOptions?.yAxis?.[0]?.inverse ? 40 : 15,
        // min: 'dataMin',
        boundaryGap: true,
        scale: true,
        nameRotate: 90,
        nameGap,
        nameLocation: 'middle',
        nameTextStyle: {
          verticalAlign: 'left'
        },
        axisLabel: {
          formatter: (value) => props.getLegendDisplayName(value) ?? value
        },
        ...mergedOptions?.yAxis?.[0],
      }],
    }
  }

  if (mergedOptions?.xAxis) {
    mergedOptions = {
      ...mergedOptions,
      xAxis: [{
        boundaryGap: true,
        scale: true,
        axisLabel: {
          formatter: (value) => props.getLegendDisplayName(value) ?? value
        },
        ...mergedOptions?.xAxis?.[0],
      }]
    }
  }

  let clickTimeout = null;
  useEffect(() => {
    if (eChartsRef && eChartsRef.current) {
      const chartInstance = eChartsRef.current.getEchartsInstance();

      chartInstance.setOption(mergedOptions, true);

      if (props.onLegendSelect) {
        chartInstance.on('legendselectchanged', (params) => {
          if (clickTimeout) {
            clearTimeout(clickTimeout);
            clickTimeout = null;

            props.onLegendSelect(params)

            chartInstance.dispatchAction({
              type: 'legendSelect',
              name: params.name,
            });
          } else {
            clickTimeout = setTimeout(() => {
              clickTimeout = null;
            }, 250);
          }
        })
      }

      if (props.onHover) {
        chartInstance.on('mouseover', (params) => {
          props.onHover(params)
        })
      }

      if (props.onMouseOut) {
        chartInstance.on('mouseout', (params) => {
          props.onMouseOut(params)
        })
      }

      if (props.onFinished) {
        if (chartInstance) {
          setTimeout(() => {
            props.onFinished();
          }, 500);
        }
      }

    }
  }, [option, props]);

  return (
    <ReactECharts
      option={mergedOptions}
      style={{ height: "100%", width: "100%" }}
      ref={eChartsRef}
      {...props}
    />
  );
}

const DynamicPieChart = ({ data, chartsConfigs, ...props }) => {
  let {
    dataPoint,
    width,
    height,
    chartType,
    dataTransformator,
    getDataTransformatorMemoDependencyArray,
    config,
    legendsPosition,
    title,
    id,
    isFullScreen,
    getColor,
    onLegendSelect,
    onHover,
    onMouseOut,
    isItemHovered,
    somethingIsHovered,
    getColumnDisplayName,
    getColumnSortFunction,
    hoverEffect
  } = props;
  const dispatch = useDispatch()

  let { invertXAxis, xAxis, yAxis, subDataPoint, showPieLabels } = config
  if (subDataPoint)
    dataPoint = subDataPoint
  const diagramConfig = chartsConfigs[0];
  const { partitions, stacks } = diagramConfig;

  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({ config })
  let { flattenData, dataStacks: preCalculatedStacks, dataPartitions: preCalculatedPartitions } = useMemo(() => dataTransformator(data, { ...config }), [dataTransformatorDependency, data])

  const { allAggregatedData, dataStacks } = useMemo(() => {
    // let flattenData = dataTransformator(data, { dataPoint, chartType, diagrams: chartsConfigs })

    let dataStacks;
    if (!stacks) dataStacks = [];
    else {
      dataStacks = preCalculatedStacks ?? flattenData?.map((e) => e[stacks])?.filter(distinctFilter);
    }

    const stacksSortFunction = (a, b) => {
      if (getColumnSortFunction)
        return getColumnSortFunction({ dataKey: stacks, aValue: a, bValue: b })
      return a > b ? 1 : -1
    }
    dataStacks.sort(stacksSortFunction)

    let allAggregatedData = [];

    if (!dataStacks.length) {
      allAggregatedData.push(
        aggregatedSumValue({
          data: flattenData,
          aggregateColumns: [dataPoint],
          groupByColumns: [partitions],
        })
      );
    } else {
      for (const stack of dataStacks) {
        const filteredData = flattenData.filter((e) => e[stacks] === stack);

        allAggregatedData.push(
          aggregatedSumValue({
            data: filteredData,
            aggregateColumns: [dataPoint],
            groupByColumns: [partitions],
          })
        );
      }
    }

    return { allAggregatedData, dataStacks }
  }, [data, dataTransformatorDependency, dataPoint, chartType, partitions, stacks, xAxis, invertXAxis, subDataPoint])

  const noOfCharts = allAggregatedData.length;

  let availableWidth = legendsPosition === 'right' ? width - legendsWidth : width
  const xOuterRadius = availableWidth / (3 * noOfCharts + 1);
  const yOuterRadius = height / 3;
  const outerRadius = Math.min(xOuterRadius, yOuterRadius);
  const marginX = availableWidth - (3 * noOfCharts + 1) * outerRadius;

  const isDoughnut = chartType === chartTypesEnums.DOUGHNUT;
  const doughnutRadiusUnit = Math.min(
    availableWidth / (5 * noOfCharts + 0.25),
    height / (5 * noOfCharts + 0.25)
  );

  const partitionsSortFunction = (a, b) => {
    if (getColumnSortFunction)
      return getColumnSortFunction({ dataKey: partitions, aValue: a[partitions], bValue: b[partitions] })
    return a[partitions] < b[partitions] ? -1 : 1
  }



  var option = useMemo(() => {
    const getValueForRepresentation = (dataPoint, stack, partition) => {
      if (stacks && partitions) {
        return `(${stack})-(${partition})`
      } else if (stacks) {
        return stack
      } else if (partitions) {
        return partition
      } else {
        return dataPoint
      }

    }

    return {
      tooltip: {
        trigger: "item",
      },
      title: [
        {
          text: title,
          left: "center",
        },
        ...dataStacks?.map((stackName, stackIndex) => ({
          subtext: !isDoughnut && getColumnDisplayName({ colName: stackName }),
          top: height / 2 - outerRadius - 40,
          textAlign: "center",
          left: isDoughnut
            ? "50%"
            : `${marginX / 2 + (3 * stackIndex + 2) * outerRadius}`,
        })),
      ],
      series: allAggregatedData?.map((data, stackIndex) => {
        const stack = dataStacks[stackIndex]

        const sortedDataWithIndex = data.map((row, indexBackup) => ({ ...row, indexBackup }))
        sortedDataWithIndex.sort(partitionsSortFunction)
        return {
          name: partitions,
          type: "pie",
          center: [
            isDoughnut
              ? `${100 * availableWidth / (2 * width)}%`
              : `${marginX / 2 + (3 * stackIndex + 2) * outerRadius}`,
            "50%",
          ],
          radius: [
            isDoughnut ? (2 * stackIndex + 1) * doughnutRadiusUnit : 0,
            isDoughnut ? (2 * stackIndex + 2) * doughnutRadiusUnit : outerRadius,
          ],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: isDoughnut ? 4 : 6,
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            show: true,
            position: showPieLabels ? "outside" : 'inside',
            formatter: showPieLabels ? '{b}({d}%)' : '{d}%'
            // formatter: '{d}%'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 14,
              fontWeight: "bold",
              position: "inside",
            },
          },
          labelLine: {
            show: true,
          },
          color: sortedDataWithIndex?.map((row, partitionIndex) => {
            let partition, valueForRepresentation
            partition = row[partitions]
            valueForRepresentation = getValueForRepresentation(dataPoint, stack, partition)

            const color = getColor && getColor({ quantity: valueForRepresentation, chartType, diagrams: chartsConfigs, })
            return color ? color : (stacks ? getItemColor(stackIndex, partitions ? partitionIndex : undefined) : getItemColor(partitionIndex));
          }),
          data: sortedDataWithIndex?.map((row, i) => {
            let partition, valueForRepresentation
            partition = row[partitions]
            valueForRepresentation = getValueForRepresentation(dataPoint, stack, partition)

            return {
              name: valueForRepresentation,
              value: row[dataPoint],
              itemStyle: {
                opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
                  row: { ...row, [stacks]: stack, [partitions]: partition },
                  chartType, xAxis, yAxis, diagrams: chartsConfigs
                })) ? 0.05 : 1
              },
              row: hoverEffect ? { ...row, [stacks]: stack, [partitions]: partition } : {}
            }
          }),
          tooltip: {
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          emphasis: {
            focus: hoverEffect ? undefined : "series",
          },
        };
      }),
    };
  }, [somethingIsHovered, allAggregatedData, dataPoint, subDataPoint, chartType, partitions, stacks, xAxis, invertXAxis, getColor])


  useEffect(() => {
    const dataToDownload = allAggregatedData?.reduce((total, current, stackIndex) => {
      const name = dataStacks[stackIndex]
      return [...total, ...(current.map(r => ({ ...r, name })))]
    }, [])

    dispatch(setDataToDownload({ id: id, data: dataToDownload }))

  }, [allAggregatedData, dataStacks, id])

  return <DynamicEchartsChart option={option} legendsPosition={legendsPosition} isFullScreen={isFullScreen}
    onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ params, config }) : undefined}
    onHover={onHover ? (params) => onHover({ params, config, point: params?.data?.row }) : undefined}
    onMouseOut={onMouseOut ? (params) => onMouseOut({ params, config }) : undefined}
    getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}

  />;
};



const DynamicPieChartMultithread = ({ data, chartsConfigs, ...props }) => {

  let {
    dataPoint,
    width,
    height,
    chartType,
    dataTransformator,
    dataTransformatorMultithread,
    getDataTransformatorMemoDependencyArray,
    config,
    legendsPosition,
    title,
    id,
    isFullScreen,
    getColor,
    onLegendSelect,
    onHover,
    onMouseOut,
    isItemHovered,
    somethingIsHovered,
    getColumnDisplayName,
    getColumnSortFunction,
    hoverEffect,
    isConfigSetting
  } = props;
  const dispatch = useDispatch()

  let { invertXAxis, xAxis, yAxis, subDataPoint, showPieLabels } = config
  if (subDataPoint)
    dataPoint = subDataPoint
  const diagramConfig = chartsConfigs[0];
  const { partitions, stacks } = diagramConfig;

  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({ config })

  const [flattenData, setFlattenData] = useState([])
  const { setIsPainting,
    isProcessing, dataTransformatorDecorator, dataAggregatorDecorator,
    isPainting, option, setOption
  } = useDynamicDashboardCalculations()


  const activeWorkerRef = useRef(null)
  const workerId = useRef(0)
  useEffect(() => {
    if (activeWorkerRef.current?.terminate) {
      activeWorkerRef.current.terminate();
    }

    workerId.current += 1

    const workerInstance = dataTransformatorMultithread()
    activeWorkerRef.current = workerInstance;

    const getTransformedData = async () => {
      const { flattenData: newFlattenData, requestId } = await workerInstance.calculate(data, { ...config }, workerId.current)
      if (requestId === workerId.current) {
        setFlattenData(newFlattenData)
      }
    }

    if (!data?.length || isConfigSetting) return
    dataTransformatorDecorator(getTransformedData)


    return () => {
      if (workerInstance.terminate) {
        workerInstance.terminate();
      }
    };
  }, [JSON.stringify(dataTransformatorDependency), data, isConfigSetting])



  const [allAggregatedData, SetAllAggregatedData] = useState([])
  const [dataStacks, SetDataStacks] = useState([])

  const stacksSortFunction = (a, b) => {
    if (getColumnSortFunction)
      return getColumnSortFunction({ dataKey: stacks, aValue: a, bValue: b })
    return a > b ? 1 : -1
  }

  const aggregaterWorkerRef = useRef(null)
  const aggregaterWorkerId = useRef(0)
  useEffect(() => {
    if (aggregaterWorkerRef.current) {
      aggregaterWorkerRef.current.terminate();
    }

    aggregaterWorkerId.current += 1
    const worker = new Worker(new URL('../../../src/pieChart.worker.js', import.meta.url));
    aggregaterWorkerRef.current = worker;

    const runWorker = async (...props) => {
      const pieChartDataAggregater = Comlink.wrap(worker);
      const result = await pieChartDataAggregater(...props);
      return result
    }
    const aggregater = async () => {
      let { allAggregatedData: allAggregatedDataTemp, dataStacks: dataStacksTemp, requestId } =
        await runWorker({ flattenData, dataPoint, partitions, stacks }, Comlink.proxy(stacksSortFunction), aggregaterWorkerId.current)
      if (requestId === aggregaterWorkerId.current) {
        SetAllAggregatedData(allAggregatedDataTemp)
        SetDataStacks(dataStacksTemp)
      }
    }

    if (!flattenData?.length || isConfigSetting) return
    dataAggregatorDecorator(aggregater, worker)
    return () => {
      worker.terminate();
    }
  },

    [data, dataTransformatorDependency, dataPoint, chartType, partitions, stacks, xAxis, invertXAxis, subDataPoint, flattenData, isConfigSetting]
  )

  const noOfCharts = allAggregatedData.length;

  let availableWidth = legendsPosition === 'right' ? width - legendsWidth : width
  const xOuterRadius = availableWidth / (3 * noOfCharts + 1);
  const yOuterRadius = height / 3;
  const outerRadius = Math.min(xOuterRadius, yOuterRadius);
  const marginX = availableWidth - (3 * noOfCharts + 1) * outerRadius;

  const isDoughnut = chartType === chartTypesEnums.DOUGHNUT;
  const doughnutRadiusUnit = Math.min(
    availableWidth / (5 * noOfCharts + 0.25),
    height / (5 * noOfCharts + 0.25)
  );

  const partitionsSortFunction = (a, b) => {
    if (getColumnSortFunction)
      return getColumnSortFunction({ dataKey: partitions, aValue: a[partitions], bValue: b[partitions] })
    return a[partitions] < b[partitions] ? -1 : 1
  }



  useEffect(() => {
    if (isConfigSetting || !flattenData?.length) return

    const getValueForRepresentation = (dataPoint, stack, partition) => {
      if (stacks && partitions) {
        return `(${stack})-(${partition})`
      } else if (stacks) {
        return stack
      } else if (partitions) {
        return partition
      } else {
        return dataPoint
      }

    }
    var optionMemoized = {
      tooltip: {
        trigger: "item",
      },
      title: [
        {
          text: title,
          left: "center",
        },
        ...dataStacks?.map((stackName, stackIndex) => ({
          subtext: !isDoughnut && getColumnDisplayName({ colName: stackName }),
          top: height / 2 - outerRadius - 40,
          textAlign: "center",
          left: isDoughnut
            ? "50%"
            : `${marginX / 2 + (3 * stackIndex + 2) * outerRadius}`,
        })),
      ],
      series: allAggregatedData?.map((data, stackIndex) => {
        const stack = dataStacks[stackIndex]

        const sortedDataWithIndex = data.map((row, indexBackup) => ({ ...row, indexBackup }))
        sortedDataWithIndex.sort(partitionsSortFunction)
        return {
          name: partitions,
          type: "pie",
          center: [
            isDoughnut
              ? `${100 * availableWidth / (2 * width)}%`
              : `${marginX / 2 + (3 * stackIndex + 2) * outerRadius}`,
            "50%",
          ],
          radius: [
            isDoughnut ? (2 * stackIndex + 1) * doughnutRadiusUnit : 0,
            isDoughnut ? (2 * stackIndex + 2) * doughnutRadiusUnit : outerRadius,
          ],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: isDoughnut ? 4 : 6,
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            show: true,
            position: showPieLabels ? "outside" : 'inside',
            formatter: showPieLabels ? '{b}({d}%)' : '{d}%'
            // formatter: '{d}%'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 14,
              fontWeight: "bold",
              position: "inside",
            },
          },
          labelLine: {
            show: true,
          },
          color: sortedDataWithIndex?.map((row, partitionIndex) => {
            let partition, valueForRepresentation
            partition = row[partitions]
            valueForRepresentation = getValueForRepresentation(dataPoint, stack, partition)

            const color = getColor && getColor({ quantity: valueForRepresentation, chartType, diagrams: chartsConfigs, })
            return color ? color : (stacks ? getItemColor(stackIndex, partitions ? partitionIndex : undefined) : getItemColor(partitionIndex));
          }),
          data: sortedDataWithIndex?.map((row, i) => {
            let partition, valueForRepresentation
            partition = row[partitions]
            valueForRepresentation = getValueForRepresentation(dataPoint, stack, partition)

            return {
              name: valueForRepresentation,
              value: row[dataPoint],
              itemStyle: {
                opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
                  row: { ...row, [stacks]: stack, [partitions]: partition },
                  chartType, xAxis, yAxis, diagrams: chartsConfigs
                })) ? 0.05 : 1
              },
              row: hoverEffect ? { ...row, [stacks]: stack, [partitions]: partition } : {}
            }
          }),
          tooltip: {
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          emphasis: {
            focus: hoverEffect ? undefined : "series",
          },
        };
      }),
    };

    setOption(optionMemoized)
  }, [somethingIsHovered, allAggregatedData, dataPoint, subDataPoint, chartType, partitions, stacks, xAxis, invertXAxis, getColor, isConfigSetting])


  useEffect(() => {
    const dataToDownload = allAggregatedData?.reduce((total, current, stackIndex) => {
      const name = dataStacks[stackIndex]
      return [...total, ...(current.map(r => ({ ...r, name })))]
    }, [])
    dispatch(setDataToDownload({ id: id, data: dataToDownload }))

  }, [allAggregatedData, dataStacks, id])


  if (isConfigSetting) return
  return <DynamicEchartsChartWithLoader isPainting={isPainting} isProcessing={isProcessing} setIsPainting={setIsPainting}
    option={option} legendsPosition={legendsPosition} isFullScreen={isFullScreen}
    onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ params, config }) : undefined}
    onHover={onHover ? (params) => onHover({ params, config, point: params?.data?.row }) : undefined}
    onMouseOut={onMouseOut ? (params) => onMouseOut({ params, config }) : undefined}
    getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
    onFinished={() => {
      setIsPainting(false)
    }} />

  // return <div className="relative w-full h-full">
  //   {(isProcessing || l) &&
  //     <div className={'absolute top-1 left-1 t-heading-l'} showLoading={isProcessing || l} >

  //       Processing data....
  //     </div>
  //   }
  //   <DynamicEchartsChart option={option} legendsPosition={legendsPosition} isFullScreen={isFullScreen}
  //     onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ params, config }) : undefined}
  //     onHover={onHover ? (params) => onHover({ params, config, point: params?.data?.row }) : undefined}
  //     onMouseOut={onMouseOut ? (params) => onMouseOut({ params, config }) : undefined}
  //     getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
  //   />;
  // </div >

};

const LegendItemWithValue = ({
  label,
  value,
  color,
  hasBullet,
  bulletType = "rectangle",
  bulletHeight,
  bulletWidth,
  direction,
  className,
  ...props
}) => {
  return (
    <Stack
      spacing={4}
      className={`w-full legend justify-between ${className} `}
      flexDirection={direction}
      {...props}
    >
      <LegendItem
        bulletWidth={bulletWidth ?? (hasBullet ? "0.8rem" : 0)}
        bulletHeight={bulletHeight ?? (hasBullet ? "0.4rem" : 0)}
        color={color}
        label={label}
        bulletType={bulletType}
      />
      <span className=" t-body-n text-gray-500">
        {/* {value >= 1000 ? addCommaToNumber(parseInt(value)) : value?.toFixed(2)}{" "} */}
        {formatNumberBasedOnUser(value)}
      </span>
    </Stack>
  );
};


const DynamicKPIMultithread = ({
  data: withoutIndexData,
  chartsConfigs,
  config,
  dataTransformator,
  dataTransformatorMultithread,
  getDataTransformatorMemoDependencyArray,
  getUnit,
  getColor,
  onLegendSelect,
  onHover,
  onMouseOut,
  isItemHovered,
  somethingIsHovered,
  getColumnDisplayName,
  getColumnSortFunction,
  isConfigSetting,
  ...props
}) => {
  let { dataPoint, height, chartType, title, xDataKey, id } = props;
  const { subDataPoint } = config
  if (subDataPoint)
    dataPoint = subDataPoint
  const dispatch = useDispatch()
  const diagramConfig = chartsConfigs[0];
  let { partitions, stacks, percentageBased, direction } = diagramConfig;

  if (chartType === chartTypesEnums.LINE || chartType === chartTypesEnums.AREA) stacks = null
  if (chartType === chartTypesEnums.KPI) xDataKey = null

  const isStacked = partitions || stacks;

  const data = withoutIndexData?.map((d, index) => ({
    ...d,
    index,
  }));
  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({ config })

  const [flattenData, setFlattenData] = useState([])
  const [preCalculatedStacks, setPreCalculatedStacks] = useState([])
  const [preCalculatedPartitions, setPreCalculatedPartitions] = useState([])

  const { isProcessing, dataTransformatorDecorator, dataAggregatorDecorator, option, setOption } = useDynamicDashboardCalculations()

  const activeWorkerRef = useRef(null)
  const workerId = useRef(0)
  useEffect(() => {
    if (activeWorkerRef.current?.terminate) {
      activeWorkerRef.current.terminate();
    }

    workerId.current += 1

    const workerInstance = dataTransformatorMultithread()
    activeWorkerRef.current = workerInstance;

    const getTransformedData = async () => {
      const { flattenData: newFlattenData, dataStacks: preCalculatedStacks, dataPartitions: preCalculatedPartitions, requestId } =
        await workerInstance.calculate(data, { ...config }, workerId.current)
      if (requestId === workerId.current) {
        setFlattenData(newFlattenData)
        setPreCalculatedStacks(preCalculatedStacks)
        setPreCalculatedPartitions(preCalculatedPartitions)
      }
    }

    if (!data?.length || isConfigSetting) return
    dataTransformatorDecorator(getTransformedData)

    return () => {
      if (workerInstance.terminate) {
        workerInstance.terminate();
      }
    };
  }, [JSON.stringify(dataTransformatorDependency), withoutIndexData])

  const [allAggregatedData, SetAllAggregatedData] = useState([])
  const [dataStacks, SetDataStacks] = useState([])
  const [dataPartitions, SetDataPartitions] = useState([])

  const sortFunction = (a, b, dataKey) => {
    if (getColumnSortFunction)
      return getColumnSortFunction({ dataKey, aValue: a, bValue: b })
    return a < b ? -1 : 1
  }

  const activeAggregaterWorkerRef = useRef(null)
  const aggregaterWorkerId = useRef(0)
  useEffect(() => {
    if (activeAggregaterWorkerRef.current) {
      activeAggregaterWorkerRef.current.terminate();
    }

    aggregaterWorkerId.current += 1
    const worker = new Worker(new URL('../../../src/kpiChart.worker.js', import.meta.url));
    activeAggregaterWorkerRef.current = worker;
    const runWorker = async (...props) => {
      const kpiChartDataAggregater = Comlink.wrap(worker);
      const result = await kpiChartDataAggregater(...props);
      return result
    }

    const aggregater = async () => {
      let { allAggregatedData: allAggregatedDataTemp, dataStacks: dataStacksTemp, dataPartitions: dataPartitionsTemp, requestId } = await runWorker({
        flattenData, dataPoint, partitions, stacks, preCalculatedStacks,
        preCalculatedPartitions, xDataKey, isStacked
      }, aggregaterWorkerId.current)
      if (requestId === aggregaterWorkerId.current) {
        SetAllAggregatedData(allAggregatedDataTemp)
        SetDataStacks(dataStacksTemp)
        SetDataPartitions(dataPartitionsTemp)
      }
    }

    if (!flattenData?.length || isConfigSetting) return
    dataAggregatorDecorator(aggregater, worker)

    return () => {
      worker.terminate();
    }
  },
    [withoutIndexData, dataTransformatorDependency, dataPoint, subDataPoint, stacks, partitions, xDataKey, flattenData, isConfigSetting,]
  )
  const isVertical = direction === "vertical";


  useEffect(() => {
    dispatch(setDataToDownload({ id: id, data: allAggregatedData }))
  }, [allAggregatedData, id])

  const unsortedDataStacks = [...dataStacks]
  const unsortedDataPartitions = [...dataPartitions]


  dataStacks.sort((a, b) => sortFunction(a, b, stacks))
  dataPartitions.sort((a, b) => sortFunction(a, b, partitions))

  const dataPointUnit = getUnit({ quantity: dataPoint, config })
  const dataPointColor = getColor ? getColor({ quantity: dataPoint, chartType, diagrams: chartsConfigs }) : undefined

  useEffect(() => {
    if (isConfigSetting || !flattenData?.length) return
    let configsCalculations = {}

    if (partitions && !stacks) {
      dataPartitions?.forEach((p, j) => {
        const unsortedPartitionIdx = unsortedDataPartitions.findIndex(partition => partition === p)
        const color = getColor && getColor({ quantity: p, chartType, diagrams: chartsConfigs, })
        const isTransparent = somethingIsHovered && isItemHovered && !isItemHovered({ row: { [partitions]: p }, chartType, diagrams: chartsConfigs })

        configsCalculations[p] = {
          value: allAggregatedData[0][p],
          color: color ?? getItemColor(0, j),
          isTransparent,
        }
      })
    }

    if (partitions && stacks) {
      dataStacks?.forEach((s, i) => {
        const unsortedStackIdx = unsortedDataStacks.findIndex(stack => stack === s)
        configsCalculations[s] = {}
        dataPartitions?.forEach((p, j) => {
          const unsortedPartitionIdx = unsortedDataPartitions.findIndex(partition => partition === p)
          const valueForColor = `(${s})-(${p})`
          const color = getColor && getColor({ quantity: valueForColor, chartType, diagrams: chartsConfigs, })
          const isTransparent = somethingIsHovered && isItemHovered && !isItemHovered(
            { row: { [stacks]: s, [partitions]: p }, chartType, diagrams: chartsConfigs }
          )
          configsCalculations[s][p] = {
            value: allAggregatedData[0][`(${s})-(${p})`],
            color: color ?? getItemColor(i, j),
            isTransparent,
            valueForColor
          }
        })
      })
    }

    setOption(configsCalculations)
  }, [withoutIndexData, flattenData, allAggregatedData, somethingIsHovered, dataPoint, partitions, stacks,
    subDataPoint, getColor, isConfigSetting])

  return (
    <div className="relative w-full h-full">
      <h4 className="text-center t-body-n capitalize mt-4">{getAxisNameWithUnit({ dataKey: title, unit: dataPointUnit })}</h4>
      {isProcessing && <ChartLoader isCalculating={true} className={'absolute top-1 left-1  w-full'} />}
      {!isProcessing &&
        <Stack
          className="w-full overflow-y-auto overflow-x-auto px-4"
          flexDirection={isVertical ? "column" : "row"}
          height={height - 48}
        >
          {!isStacked ? (
            <LegendItemWithValue
              label={getColumnDisplayName({ colName: dataPoint })}
              value={allAggregatedData[0][dataPoint]}
              color={dataPointColor ?? getItemColor(0)}
              bulletType="circle"
              bulletHeight={'1.2rem'}
              bulletWidth={'1.2rem'}
              hasBullet={true}
              direction={"column"}
              onClick={onLegendSelect ? () => onLegendSelect({ params: { name: dataPoint }, config }) : undefined}
              className={`w-full py-1 ${onLegendSelect ? 'cursor-pointer' : ''} }`
              }
            />
          ) : partitions && !stacks ? (
            dataPartitions?.map((p, i) => {
              const value = option?.[p]?.value
              const color = option?.[p]?.color
              const isTransparent = option?.[p]?.isTransparent

              return <LegendItemWithValue
                label={getColumnDisplayName({ colName: p })}
                bulletType="circle"
                bulletHeight={'1.2rem'}
                bulletWidth={'1.2rem'}
                hasBullet={true}
                direction={isVertical ? "row" : "column"}
                value={value}
                color={color}
                onClick={onLegendSelect ? () => onLegendSelect({ params: { name: p }, config }) : undefined}
                onMouseEnter={onHover ? () => onHover({ config, point: { [partitions]: p } }) : undefined}
                onMouseLeave={onMouseOut ? () => onMouseOut({ config }) : undefined}
                className={`w-full py-1  ${isTransparent ? 'opacity-[0.2]' : 'opacity-[1]'}
            ${onLegendSelect ? 'cursor-pointer' : ''}  ${somethingIsHovered && !isTransparent && 'bg-blue-50'}`
                }
              />
            }
            )
          ) : (
            <Stack
              flexDirection={isVertical ? "column" : "row"}
              justifyContent={"space-between"}
              className={"h-full w-full"}
            >
              {dataStacks?.map((s, i) => {
                return (
                  <div className="w-full h-full p-4 text-center t-body-n capitalize">
                    <p className="mt-2">{getColumnDisplayName({ colName: s })}</p>
                    <Stack
                      flexDirection={"column"}
                      justifyContent={"space-between"}
                      className="w-full"
                    >
                      {dataPartitions?.map((p, j) => {
                        const value = option?.[s]?.[p]?.value
                        const valueForColor = option?.[s]?.[p]?.valueForColor
                        const color = option?.[s]?.[p]?.color
                        const isTransparent = option?.[s]?.[p]?.isTransparent

                        return <LegendItemWithValue
                          label={getColumnDisplayName({ colName: p })}
                          value={value}
                          bulletType="circle"
                          bulletHeight={'1.2rem'}
                          bulletWidth={'1.2rem'}
                          hasBullet={true}
                          color={color}
                          onClick={onLegendSelect ? () => onLegendSelect({ params: { name: valueForColor }, config }) : undefined}
                          onMouseEnter={onHover ? () => onHover({ config, point: { [stacks]: s, [partitions]: p } }) : undefined}
                          onMouseLeave={onMouseOut ? () => onMouseOut({ config }) : undefined}
                          className={`w-full py-1 ${isTransparent ? 'opacity-[0.2]' : 'opacity-[1]'}
                           ${onLegendSelect ? 'cursor-pointer' : ''}  ${somethingIsHovered && !isTransparent && 'bg-blue-50'}`
                          }
                        />
                      }
                      )}
                    </Stack>
                  </div>
                );
              })}
            </Stack>
          )}

          <Legend />
          <Tooltip />
        </Stack>
      }
    </div>
  );

};


const DynamicKPI = ({
  data: withoutIndexData,
  chartsConfigs,
  config,
  dataTransformator,
  getDataTransformatorMemoDependencyArray,
  getUnit,
  getColor,
  onLegendSelect,
  onHover,
  onMouseOut,
  isItemHovered,
  somethingIsHovered,
  getColumnDisplayName,
  getColumnSortFunction,
  ...props
}) => {
  let { dataPoint, height, chartType, title, xDataKey, id } = props;
  const { subDataPoint } = config
  if (subDataPoint)
    dataPoint = subDataPoint
  const dispatch = useDispatch()
  const diagramConfig = chartsConfigs[0];
  let { partitions, stacks, percentageBased, direction } = diagramConfig;

  if (chartType === chartTypesEnums.LINE || chartType === chartTypesEnums.AREA) stacks = null
  if (chartType === chartTypesEnums.KPI) xDataKey = null

  const isStacked = partitions || stacks;

  const data = withoutIndexData?.map((d, index) => ({
    ...d,
    index,
  }));
  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({ config })
  let { flattenData, dataStacks: preCalculatedStacks, dataPartitions: preCalculatedPartitions } = useMemo(() => dataTransformator(data, { ...config }), [dataTransformatorDependency, withoutIndexData])

  let { kpiAggregatedDataMemoized, dataStacks, dataPartitions } = useMemo(() => {
    let dataStacks
    if (!stacks) dataStacks = [];
    else {
      dataStacks = preCalculatedStacks ?? flattenData?.map((e) => e[stacks])?.filter(distinctFilter);
    }

    let dataPartitions;
    if (!partitions) dataPartitions = [];
    else {
      dataPartitions = preCalculatedPartitions ?? flattenData
        ?.map((e) => e[partitions])
        ?.filter(distinctFilter);
    }

    const pivotColumn = partitions && stacks
      ? `(${stacks})-(${partitions})`
      : partitions ?? stacks
    if (partitions && stacks) {
      flattenData = flattenData?.map((e) => ({
        ...e,
        [pivotColumn]: `(${e[stacks]})-(${e[partitions]})`,
        [stacks]: null,
        [partitions]: null,
      }));
    }

    let kpiAggregatedData;
    if (!isStacked) {
      kpiAggregatedData = aggregatedSumValue({
        data: flattenData,
        aggregateColumns: [dataPoint],
        groupByColumns: [],
      });
    } else {
      // var pivotColumnValues = flattenData
      //   ?.map((e) => e[pivotColumn])
      //   ?.filter(distinctFilter);
      let pivotColumnValuesAll = dataStacks?.map(s => dataPartitions?.map(p => `(${s})-(${p})`))
      pivotColumnValuesAll = pivotColumnValuesAll?.reduce((total, current) => [...total, ...current], [])

      var pivotColumnValues = (pivotColumn === partitions) ? dataPartitions : (pivotColumn === stacks) ? dataStacks : pivotColumnValuesAll
        ?.filter(sp => flattenData?.some(row => row[pivotColumn] === sp));

      // Remove hardcoded value
      flattenData = aggregatedSumValue({
        data: flattenData,
        aggregateColumns: [dataPoint],
        groupByColumns: [xDataKey, pivotColumn, "dataPoint"],
      });

      const pivotedData = pivotData(flattenData, pivotColumn, dataPoint);
      kpiAggregatedData = aggregatedSumValue({
        data: pivotedData,
        aggregateColumns: pivotColumnValues,
        groupByColumns: [],
      });
    }

    return { kpiAggregatedDataMemoized: kpiAggregatedData, dataStacks, dataPartitions }
  }, [withoutIndexData, dataTransformatorDependency, dataPoint, subDataPoint, stacks, partitions, xDataKey])
  const isVertical = direction === "vertical";


  useEffect(() => {
    dispatch(setDataToDownload({ id: id, data: kpiAggregatedDataMemoized }))
  }, [kpiAggregatedDataMemoized, id])

  const unsortedDataStacks = [...dataStacks]
  const unsortedDataPartitions = [...dataPartitions]

  const sortFunction = (a, b, dataKey) => {
    if (getColumnSortFunction)
      return getColumnSortFunction({ dataKey, aValue: a, bValue: b })
    return a < b ? -1 : 1
  }

  dataStacks.sort((a, b) => sortFunction(a, b, stacks))
  dataPartitions.sort((a, b) => sortFunction(a, b, partitions))

  const dataPointUnit = getUnit({ quantity: dataPoint, config })
  const dataPointColor = getColor ? getColor({ quantity: dataPoint, chartType, diagrams: chartsConfigs }) : undefined

  const configsCalculationsMemoized = useMemo(() => {
    let configsCalculations = {}

    if (partitions && !stacks) {
      dataPartitions?.forEach((p, j) => {
        const unsortedPartitionIdx = unsortedDataPartitions.findIndex(partition => partition === p)
        const color = getColor && getColor({ quantity: p, chartType, diagrams: chartsConfigs, })
        const isTransparent = somethingIsHovered && isItemHovered && !isItemHovered({ row: { [partitions]: p }, chartType, diagrams: chartsConfigs })

        configsCalculations[p] = {
          value: kpiAggregatedDataMemoized[0][p],
          color: color ?? getItemColor(0, j),
          isTransparent,
        }
      })
    }

    if (partitions && stacks) {
      dataStacks?.forEach((s, i) => {
        const unsortedStackIdx = unsortedDataStacks.findIndex(stack => stack === s)
        configsCalculations[s] = {}
        dataPartitions?.forEach((p, j) => {
          const unsortedPartitionIdx = unsortedDataPartitions.findIndex(partition => partition === p)
          const valueForColor = `(${s})-(${p})`
          const color = getColor && getColor({ quantity: valueForColor, chartType, diagrams: chartsConfigs, })
          const isTransparent = somethingIsHovered && isItemHovered && !isItemHovered(
            { row: { [stacks]: s, [partitions]: p }, chartType, diagrams: chartsConfigs }
          )
          configsCalculations[s][p] = {
            value: kpiAggregatedDataMemoized[0][`(${s})-(${p})`],
            color: color ?? getItemColor(i, j),
            isTransparent,
            valueForColor
          }
        })
      })
    }
    return configsCalculations
  }, [withoutIndexData, dataPoint, subDataPoint, stacks, partitions, getColor, config])

  return (
    <>
      <h4 className="text-center t-body-n capitalize mt-4">{getAxisNameWithUnit({ dataKey: title, unit: dataPointUnit })}</h4>
      <Stack
        className="w-full overflow-y-auto overflow-x-auto px-4"
        flexDirection={isVertical ? "column" : "row"}
        height={height - 48}
      >
        {!isStacked ? (
          <LegendItemWithValue
            label={getColumnDisplayName({ colName: dataPoint })}
            value={kpiAggregatedDataMemoized[0][dataPoint]}
            color={dataPointColor ?? getItemColor(0)}
            bulletType="circle"
            bulletHeight={'1.2rem'}
            bulletWidth={'1.2rem'}
            hasBullet={true}
            direction={"column"}
            onClick={onLegendSelect ? () => onLegendSelect({ params: { name: dataPoint }, config }) : undefined}
            className={`w-full py-1 ${onLegendSelect ? 'cursor-pointer' : ''} }`
            }
          />
        ) : partitions && !stacks ? (
          dataPartitions?.map((p, i) => {
            const value = configsCalculationsMemoized?.[p]?.value
            const color = configsCalculationsMemoized?.[p]?.color
            const isTransparent = configsCalculationsMemoized?.[p]?.isTransparent

            return <LegendItemWithValue
              label={getColumnDisplayName({ colName: p })}
              bulletType="circle"
              bulletHeight={'1.2rem'}
              bulletWidth={'1.2rem'}
              hasBullet={true}
              direction={isVertical ? "row" : "column"}
              value={value}
              color={color}
              onClick={onLegendSelect ? () => onLegendSelect({ params: { name: p }, config }) : undefined}
              onMouseEnter={onHover ? () => onHover({ config, point: { [partitions]: p } }) : undefined}
              onMouseLeave={onMouseOut ? () => onMouseOut({ config }) : undefined}
              className={`w-full py-1  ${isTransparent ? 'opacity-[0.2]' : 'opacity-[1]'}
            ${onLegendSelect ? 'cursor-pointer' : ''}  ${somethingIsHovered && !isTransparent && 'bg-blue-50'}`
              }
            />
          }
          )
        ) : (
          <Stack
            flexDirection={isVertical ? "column" : "row"}
            justifyContent={"space-between"}
            className={"h-full w-full"}
          >
            {dataStacks?.map((s, i) => {
              return (
                <div className="w-full h-full p-4 text-center t-body-n capitalize">
                  <p className="mt-2">{getColumnDisplayName({ colName: s })}</p>
                  <Stack
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    className="w-full"
                  >
                    {dataPartitions?.map((p, j) => {
                      const value = configsCalculationsMemoized?.[s]?.[p]?.value
                      const valueForColor = configsCalculationsMemoized?.[s]?.[p]?.valueForColor
                      const color = configsCalculationsMemoized?.[s]?.[p]?.color
                      const isTransparent = configsCalculationsMemoized?.[s]?.[p]?.isTransparent

                      return <LegendItemWithValue
                        label={getColumnDisplayName({ colName: p })}
                        value={value}
                        bulletType="circle"
                        bulletHeight={'1.2rem'}
                        bulletWidth={'1.2rem'}
                        hasBullet={true}
                        color={color}
                        onClick={onLegendSelect ? () => onLegendSelect({ params: { name: valueForColor }, config }) : undefined}
                        onMouseEnter={onHover ? () => onHover({ config, point: { [stacks]: s, [partitions]: p } }) : undefined}
                        onMouseLeave={onMouseOut ? () => onMouseOut({ config }) : undefined}
                        className={`w-full py-1 ${isTransparent ? 'opacity-[0.2]' : 'opacity-[1]'}
                           ${onLegendSelect ? 'cursor-pointer' : ''}  ${somethingIsHovered && !isTransparent && 'bg-blue-50'}`
                        }
                      />
                    }
                    )}
                  </Stack>
                </div>
              );
            })}
          </Stack>
        )}

        <Legend />
        <Tooltip />
      </Stack>
    </>
  );

};

const DynamicStackedChart = ({
  data: withoutIndexData,
  chartsConfigs,
  config,
  dataTransformator,
  getDataTransformatorMemoDependencyArray,
  ...props
}) => {
  let { chartType, title, xDataKey, sort, legendsPosition, isFullScreen, id,
    dataPoint,
    getUnit,
    getColor,
    onLegendSelect,
    onHover,
    onMouseOut,
    isItemHovered,
    somethingIsHovered,
    getColumnDisplayName,
    getColumnSortFunction,
    hoverEffect
  } = props;
  const dispatch = useDispatch()
  const { subDataPoint, invertXAxis, invertYAxis, xAxis, yAxis } = config

  if (subDataPoint)
    dataPoint = subDataPoint

  const diagramConfig = chartsConfigs[0];
  let { partitions, stacks, percentageBased, direction } = diagramConfig;

  if (chartType === chartTypesEnums.LINE || chartType === chartTypesEnums.AREA) stacks = null
  if (chartType === chartTypesEnums.KPI) xDataKey = null

  const isStacked = partitions || stacks;

  const data = withoutIndexData?.map((d, index) => ({
    ...d,
    index,
  }));
  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({ config })
  let { flattenData, dataStacks: preCalculatedStacks, dataPartitions: preCalculatedPartitions } = useMemo(() => dataTransformator(data, { ...config }), [dataTransformatorDependency, withoutIndexData])

  const sortFunction = (a, b, dataKey) => {
    if (getColumnSortFunction)
      return getColumnSortFunction({ dataKey, aValue: a, bValue: b })
    return a < b ? -1 : 1
  }


  const { allAggregatedData, dataStacks, dataPartitions, xAxisValues, pivotColumnValues } = useMemo(() => {
    let dataStacks;
    if (!stacks) dataStacks = [];
    else {
      dataStacks = preCalculatedStacks ?? flattenData?.map((e) => e[stacks])?.filter(distinctFilter);
      dataStacks.sort((a, b) => sortFunction(a, b, stacks))
    }
    let dataPartitions;
    if (!partitions) dataPartitions = [];
    else {
      dataPartitions = preCalculatedPartitions ?? flattenData
        ?.map((e) => e[partitions])
        ?.filter(distinctFilter);
      dataPartitions.sort((a, b) => sortFunction(a, b, partitions))
    }

    let allAggregatedData = [];
    const pivotColumn =
      (chartType === chartTypesEnums.BAR)
        ? partitions && stacks
          ? `(${stacks}) - (${partitions})`
          : partitions || stacks
        : partitions;

    if (partitions && stacks && (chartType === chartTypesEnums.BAR)) {
      flattenData = flattenData?.map((e) => ({
        ...e,
        [pivotColumn]: `Stack=${e[stacks]}&Partition=${e[partitions]}`,
        [stacks]: null,
        [partitions]: null,
      }));
    }

    if (!isStacked) {
      allAggregatedData = aggregatedSumValue({
        data: flattenData,
        aggregateColumns: [dataPoint],
        groupByColumns: [xDataKey],
      });
    } else {
      let pivotColumnValuesAll = dataStacks?.map(s => dataPartitions?.map(p => `Stack=${s}&Partition=${p}`))
      pivotColumnValuesAll = pivotColumnValuesAll?.reduce((total, current) => [...total, ...current], [])

      var pivotColumnValues = (pivotColumn === partitions) ? dataPartitions : (pivotColumn === stacks) ? dataStacks : pivotColumnValuesAll
        ?.filter(sp => flattenData?.some(row => row[pivotColumn] === sp));

      // Remove hardcoded value
      flattenData = aggregatedSumValue({
        data: flattenData,
        aggregateColumns: [dataPoint],
        groupByColumns: [xDataKey, pivotColumn, "dataPoint"],
      });

      const pivotedData = pivotData(flattenData, pivotColumn, dataPoint);

      allAggregatedData = aggregatedSumValue({
        data: pivotedData,
        aggregateColumns: pivotColumnValues,
        groupByColumns: [xDataKey],
      });
    }

    // TODO: Remove this static values (Use enums at least)
    let xAxisValues = []
    if (!sort)
      sort = 'x'

    if ([chartTypesEnums.BAR, chartTypesEnums.AREA, chartTypesEnums.LINE].includes(chartType)) {

      if (sort === 'x' || sort === undefined) {
        if (getColumnSortFunction)
          allAggregatedData.sort((a, b) => getColumnSortFunction({ dataKey: xDataKey, aValue: a[xDataKey], bValue: b[xDataKey] }))
        else
          allAggregatedData = sortData({ data: allAggregatedData, dataKey: xDataKey })
      }
      // Remove Hardcoded value
      if (sort === 'y' || sort === 'All values') {
        if (isStacked)
          allAggregatedData = sortDataAccumulative({ data: allAggregatedData, dataKeys: pivotColumnValues })
        else
          allAggregatedData = sortData({ data: allAggregatedData, dataKey: dataPoint })
      }

      // Remove Hardcoded value
      xAxisValues = xDataKey ? allAggregatedData?.map((row) => row[xDataKey]) : ["Total"];
    }
    if (chartType === chartTypesEnums.LINE) {
      // Remove Hardcoded value
      if (isStacked && sort === 'All values') {
        const sorted = {};
        pivotColumnValues.forEach((v) => {
          const pivotValues = allAggregatedData?.map(row => row[v]);
          pivotValues.sort((a, b) => a - b);
          sorted[v] = pivotValues;
        });
        const sortedAllAggregatedData = [];
        for (const i in allAggregatedData) {
          const sortedIthIndex = {};
          for (const v of pivotColumnValues) {
            sortedIthIndex[v] = sorted[v][i];
          }
          sortedAllAggregatedData.push({ i, ...sortedIthIndex });
        }
        allAggregatedData = sortedAllAggregatedData;
        // Remove Hardcoded value
        xAxisValues = xDataKey ? allAggregatedData?.map((row, index) => index) : ["Total"];
      }

    }

    return { allAggregatedData, dataStacks, dataPartitions, xAxisValues, pivotColumnValues }

  }, [withoutIndexData, dataTransformatorDependency, dataPoint, subDataPoint, chartType, partitions, stacks, xDataKey, sort])


  const isVertical = direction === "vertical";

  const oneToNArray = [...Array(xAxisValues?.length)?.keys() ?? []]

  let xAxisOption = !isVertical
    ? {
      data: (chartType === chartTypesEnums.LINE && sort === 'All values') ? (invertXAxis ? reverse(oneToNArray) : oneToNArray) : xAxisValues,
      axisPointer: {
        type: "shadow",
      },
      inverse: invertXAxis
    }
    : {
      type: "value",
      inverse: invertXAxis,
      scale: false
    };

  const xUnit = getUnit({ quantity: xDataKey, config })
  const xAxisName = getAxisNameWithUnit({ dataKey: getColumnDisplayName({ colName: xDataKey }), unit: xUnit })

  const dataPointUnit = getUnit({ quantity: dataPoint })
  const yAxisName = getAxisNameWithUnit({ dataKey: getColumnDisplayName({ colName: dataPoint }), unit: dataPointUnit })

  xAxisOption = { ...xAxisOption, nameGap: 25, nameLocation: 'center', name: !isVertical ? xAxisName : yAxisName }

  let yAxisOption = isVertical
    ? {
      data: (chartType === chartTypesEnums.LINE && sort === 'All values') ? (invertXAxis ? reverse(oneToNArray) : oneToNArray) : xAxisValues,
      axisPointer: {
        type: "shadow",
      },
      inverse: invertYAxis
    }
    : {
      type: "value",
      name: getColumnDisplayName({ colName: dataPoint }),
      inverse: invertYAxis,
      scale: false
    };

  yAxisOption = { ...yAxisOption, name: !isVertical ? yAxisName : xAxisName }
  const dataPointColor = getColor ? getColor({ quantity: dataPoint, chartType, diagrams: chartsConfigs }) : undefined

  let partitionsCounter = -1

  var option = useMemo(() => {
    return {
      xAxis: [xAxisOption,],
      yAxis: [yAxisOption,],
      series: !isStacked
        ? [
          {
            name: dataPoint,
            color: dataPointColor ?? getItemColor(0),
            type: chartType === chartTypesEnums.AREA ? chartTypesEnums.LINE : chartType,
            areaStyle: chartType === chartTypesEnums.AREA ? {} : undefined,
            tooltip: {
              valueFormatter: echartsDefaultTooltipFormatter,
            },
            data: allAggregatedData?.map((row) => {
              return {
                value: row[dataPoint],
                itemStyle: {
                  opacity: (somethingIsHovered && isItemHovered && !isItemHovered({ row, xAxis, yAxis, chartType, diagrams: chartsConfigs })) ? 0.05 : 1
                },
                row: hoverEffect ? row : {}

              }
            }),
            maxValue: Math.max(...allAggregatedData?.map(row => row[dataPoint] ?? 0))
          },
        ]
        : pivotColumnValues?.map((value, i) => {
          let partition, stack, valueForRepresentation
          if (stacks && partitions) {
            partition = value?.split('&Partition=')?.[1]
            stack = value?.split('&Partition=')?.[0]?.replace('Stack=', '')
            valueForRepresentation = `(${stack})-(${partition})`

            if (i > 0) {
              const previousValue = pivotColumnValues[i - 1]
              const previousStack = previousValue?.split('&Partition=')?.[0]?.replace('Stack=', '')
              if (previousStack !== stack)
                partitionsCounter = 0
              else {
                partitionsCounter++
              }
            } else {
              partitionsCounter++
            }
          } else {
            partition = partitions ? value : undefined
            stack = stacks ? value : undefined
            valueForRepresentation = value
          }

          const stackIndex = dataStacks?.findIndex((s) => stack === s);
          const color = getColor && getColor({ quantity: valueForRepresentation, chartType, diagrams: chartsConfigs, })
          return {
            type: chartType === chartTypesEnums.AREA ? chartTypesEnums.LINE : chartType,
            areaStyle: chartType === chartTypesEnums.AREA ? {} : undefined,
            color: color ? color : ((stacks && partitions) ? getItemColor(stackIndex, partitionsCounter) : getItemColor(i)),
            stack:
              chartType === chartTypesEnums.LINE
                ? null
                : chartType === chartTypesEnums.AREA
                  ? "0"
                  : stack ?? "0",
            tooltip: {
              valueFormatter: echartsDefaultTooltipFormatter,
            },
            // data: allAggregatedData?.filter((row, i) => i < 10)?.map((row) => {
            data: allAggregatedData?.map((row) => {
              return {
                value: row[value],
                itemStyle: {
                  opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
                    row: { ...row, [stacks]: stack, [partitions]: partition },
                    xAxis, yAxis, chartType, diagrams: chartsConfigs
                  })) ? 0.05 : 1
                },
                row: hoverEffect ? { ...row, [stacks]: stack, [partitions]: partition } : {}
              }
            }),
            maxValue: Math.max(...allAggregatedData?.map(row => row[value] ?? 0)),
            emphasis: {
              focus: hoverEffect ? undefined : "series",
            },
          };
        }),
    };
  }, [somethingIsHovered, allAggregatedData, dataPoint, chartType, partitions, stacks,
    xAxis, yAxis, invertYAxis, subDataPoint, dataPoint, invertXAxis, pivotColumnValues, getColor])


  useEffect(() => {
    dispatch(setDataToDownload({ id, data: allAggregatedData }))
  }, [id, allAggregatedData])

  const longestY = getArrayLongestElement(isVertical ? xAxisValues : option.series?.map(s => s.maxValue ?? 0))


  return <DynamicEchartsChart option={option} title={title} legendsPosition={legendsPosition} isFullScreen={isFullScreen}
    onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ params, config }) : undefined}
    onHover={onHover ? (params) => onHover({ params, config, point: params?.data?.row }) : undefined}
    onMouseOut={onMouseOut ? (params) => onMouseOut({ params, config }) : undefined}
    getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
    longestY={longestY}
  />;
};



const DynamicStackedChartMultithread = ({
  data: withoutIndexData,
  chartsConfigs,
  config,
  dataTransformator,
  dataTransformatorMultithread,
  getDataTransformatorMemoDependencyArray,
  isConfigSetting,
  ...props
}) => {
  let { chartType, title, xDataKey, sort, legendsPosition, isFullScreen, id,
    dataPoint,
    getUnit,
    getColor,
    onLegendSelect,
    onHover,
    onMouseOut,
    isItemHovered,
    somethingIsHovered,
    getColumnDisplayName,
    getColumnSortFunction,
    hoverEffect
  } = props;
  const dispatch = useDispatch()
  const { subDataPoint, invertXAxis, invertYAxis, xAxis, yAxis } = config

  if (subDataPoint)
    dataPoint = subDataPoint

  const diagramConfig = chartsConfigs[0];
  let { partitions, stacks, percentageBased, direction } = diagramConfig;

  if (chartType === chartTypesEnums.LINE || chartType === chartTypesEnums.AREA) stacks = null
  if (chartType === chartTypesEnums.KPI) xDataKey = null

  const isStacked = partitions || stacks;

  const data = withoutIndexData?.map((d, index) => ({
    ...d,
    index,
  }));
  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({ config })

  const [flattenData, setFlattenData] = useState([])
  const [preCalculatedStacks, setPreCalculatedStacks] = useState([])
  const [preCalculatedPartitions, setPreCalculatedPartitions] = useState([])

  const { setIsPainting,
    isProcessing, dataTransformatorDecorator, dataAggregatorDecorator,
    isPainting, option, setOption
  } = useDynamicDashboardCalculations()

  const activeWorkerRef = useRef(null)
  const workerId = useRef(0)
  useEffect(() => {
    if (activeWorkerRef.current?.terminate) {
      activeWorkerRef.current.terminate();
    }

    workerId.current += 1

    const workerInstance = dataTransformatorMultithread()
    activeWorkerRef.current = workerInstance;

    const getTransformedData = async () => {
      const { flattenData: newFlattenData, dataStacks: preCalculatedStacks, dataPartitions: preCalculatedPartitions, requestId } =
        await workerInstance.calculate(data, { ...config }, workerId.current)
      if (requestId === workerId.current) {
        setFlattenData(newFlattenData)
        setPreCalculatedStacks(preCalculatedStacks)
        setPreCalculatedPartitions(preCalculatedPartitions)
      }
    }

    if (!data?.length || isConfigSetting) return
    dataTransformatorDecorator(getTransformedData)
    return () => {
      if (workerInstance.terminate) {
        workerInstance.terminate();
      }
    };

  }, [JSON.stringify(dataTransformatorDependency), withoutIndexData, isConfigSetting])

  const [allAggregatedData, SetAllAggregatedData] = useState([])
  const [dataStacks, SetDataStacks] = useState([])
  const [xAxisValues, SetXAxisValues] = useState([])
  const [pivotColumnValues, SetPivotColumnValues] = useState([])

  const sortFunction = (a, b, dataKey) => {
    if (getColumnSortFunction)
      return getColumnSortFunction({ dataKey, aValue: a, bValue: b })
    return a < b ? -1 : 1
  }


  const sortAggregatedDataOnX = (aggregatedData) => {
    const sorted = aggregatedData?.toSorted((a, b) => getColumnSortFunction({ dataKey: xDataKey, aValue: a[xDataKey], bValue: b[xDataKey] }))
    return sorted
  }

  const sortStacksAndPartitions = (array, colName) => {
    return array?.toSorted((a, b) => sortFunction(a, b, colName))
  }

  const activeAggregaterWorkerRef = useRef(null)
  const aggregaterWorkerId = useRef(0)
  useEffect(() => {
    if (activeAggregaterWorkerRef.current) {
      activeAggregaterWorkerRef.current.terminate();
    }

    aggregaterWorkerId.current += 1
    const worker = new Worker(new URL('../../../src/stackedChart.worker.js', import.meta.url));
    activeAggregaterWorkerRef.current = worker;
    const runWorker = async (...props) => {
      const stackedChartDataAggregater = Comlink.wrap(worker);
      const result = await stackedChartDataAggregater(...props);
      return result
    }

    const aggregater = async () => {
      let { allAggregatedDataTemp, dataStacksTemp, xAxisValuesTemp, pivotColumnValuesTemp, requestId } = await runWorker({
        flattenData, dataPoint, partitions, stacks, preCalculatedStacks,
        preCalculatedPartitions,
        chartType, sort, xDataKey, isStacked,
      }, getColumnSortFunction ? Comlink.proxy(sortAggregatedDataOnX) : undefined, Comlink.proxy(sortStacksAndPartitions), aggregaterWorkerId.current)

      if (requestId === aggregaterWorkerId.current) {
        SetAllAggregatedData(allAggregatedDataTemp)
        SetDataStacks(dataStacksTemp)
        SetXAxisValues(xAxisValuesTemp)
        SetPivotColumnValues(pivotColumnValuesTemp)
      }
    }

    if (!flattenData?.length || isConfigSetting) return
    dataAggregatorDecorator(aggregater, worker)

    return () => {
      worker.terminate();
    }
  },
    [withoutIndexData, dataTransformatorDependency, dataPoint, subDataPoint, chartType, partitions, stacks, xDataKey, sort, flattenData, isConfigSetting]
    // [partitions, stacks, sort, flattenData]
  )

  const isVertical = direction === "vertical";

  const oneToNArray = [...Array(xAxisValues?.length)?.keys() ?? []]

  let xAxisOption = !isVertical
    ? {
      data: (chartType === chartTypesEnums.LINE && sort === 'All values') ? (invertXAxis ? reverse(oneToNArray) : oneToNArray) : xAxisValues,
      axisPointer: {
        type: "shadow",
      },
      inverse: invertXAxis
    }
    : {
      type: "value",
      inverse: invertXAxis,
      scale: false
    };

  const xUnit = getUnit({ quantity: xDataKey, config })
  const xAxisName = getAxisNameWithUnit({ dataKey: getColumnDisplayName({ colName: xDataKey }), unit: xUnit })

  const dataPointUnit = getUnit({ quantity: dataPoint })
  const yAxisName = getAxisNameWithUnit({ dataKey: getColumnDisplayName({ colName: dataPoint }), unit: dataPointUnit })

  xAxisOption = { ...xAxisOption, nameGap: 25, nameLocation: 'center', name: !isVertical ? xAxisName : yAxisName }

  let yAxisOption = isVertical
    ? {
      data: (chartType === chartTypesEnums.LINE && sort === 'All values') ? (invertXAxis ? reverse(oneToNArray) : oneToNArray) : xAxisValues,
      axisPointer: {
        type: "shadow",
      },
      inverse: invertYAxis
    }
    : {
      type: "value",
      name: getColumnDisplayName({ colName: dataPoint }),
      inverse: invertYAxis,
      scale: false
    };

  yAxisOption = { ...yAxisOption, name: !isVertical ? yAxisName : xAxisName }
  const dataPointColor = getColor ? getColor({ quantity: dataPoint, chartType, diagrams: chartsConfigs }) : undefined

  let partitionsCounter = -1


  useEffect(() => {
    if (isConfigSetting || !flattenData?.length) return
    const newOptions = {
      xAxis: [xAxisOption,],
      yAxis: [yAxisOption,],
      series: !isStacked
        ? [
          {
            name: dataPoint,
            color: dataPointColor ?? getItemColor(0),
            type: chartType === chartTypesEnums.AREA ? chartTypesEnums.LINE : chartType,
            areaStyle: chartType === chartTypesEnums.AREA ? {} : undefined,
            tooltip: {
              valueFormatter: echartsDefaultTooltipFormatter,
            },
            data: allAggregatedData?.map((row) => {
              return {
                value: row[dataPoint],
                itemStyle: {
                  opacity: (somethingIsHovered && isItemHovered && !isItemHovered({ row, xAxis, yAxis, chartType, diagrams: chartsConfigs })) ? 0.05 : 1
                },
                row: hoverEffect ? row : {}

              }
            }),
            maxValue: Math.max(...allAggregatedData?.map(row => row[dataPoint] ?? 0))
          },
        ]
        : pivotColumnValues?.map((value, i) => {
          let partition, stack, valueForRepresentation
          if (stacks && partitions) {
            partition = value?.split('&Partition=')?.[1]
            stack = value?.split('&Partition=')?.[0]?.replace('Stack=', '')
            valueForRepresentation = `(${stack})-(${partition})`

            if (i > 0) {
              const previousValue = pivotColumnValues[i - 1]
              const previousStack = previousValue?.split('&Partition=')?.[0]?.replace('Stack=', '')
              if (previousStack !== stack)
                partitionsCounter = 0
              else {
                partitionsCounter++
              }
            } else {
              partitionsCounter++
            }
          } else {
            partition = partitions ? value : undefined
            stack = stacks ? value : undefined
            valueForRepresentation = value
          }

          const stackIndex = dataStacks?.findIndex((s) => stack === s);
          const color = getColor && getColor({ quantity: valueForRepresentation, chartType, diagrams: chartsConfigs, })
          return {
            // 35 -  12 - 8
            // sampling: 'lttb',
            large: true,
            largeThreshold: 1000,
            progressive: 500,
            progressiveThreshold: 1000,
            name: valueForRepresentation,
            type: chartType === chartTypesEnums.AREA ? chartTypesEnums.LINE : chartType,
            areaStyle: chartType === chartTypesEnums.AREA ? {} : undefined,
            color: color ? color : ((stacks && partitions) ? getItemColor(stackIndex, partitionsCounter) : getItemColor(i)),
            stack:
              chartType === chartTypesEnums.LINE
                ? null
                : chartType === chartTypesEnums.AREA
                  ? "0"
                  : stack ?? "0",
            tooltip: {
              valueFormatter: echartsDefaultTooltipFormatter,
            },
            // data: allAggregatedData?.filter((row, i) => i < 10)?.map((row) => {
            data: allAggregatedData?.map((row) => {
              return {
                value: row[value],
                itemStyle: {
                  opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
                    row: { ...row, [stacks]: stack, [partitions]: partition },
                    xAxis, yAxis, chartType, diagrams: chartsConfigs
                  })) ? 0.05 : 1
                },
                row: hoverEffect ? { ...row, [stacks]: stack, [partitions]: partition } : {}
              }
            }),
            maxValue: Math.max(...allAggregatedData?.map(row => row[value] ?? 0)),
            emphasis: {
              focus: hoverEffect ? undefined : "series",
            },
          };
        }),
    };
    setOption({ ...newOptions })
  }, [somethingIsHovered, allAggregatedData, dataPoint, chartType, partitions, stacks,
    xAxis, yAxis, invertYAxis, subDataPoint, dataPoint, invertXAxis, pivotColumnValues, getColor, isConfigSetting])

  useEffect(() => {
    dispatch(setDataToDownload({ id, data: allAggregatedData }))
  }, [id, allAggregatedData])

  const longestY = getArrayLongestElement(isVertical ? xAxisValues : option.series?.map(s => s.maxValue ?? 0))

  if (isConfigSetting) return
  return <DynamicEchartsChartWithLoader isPainting={isPainting} isProcessing={isProcessing} setIsPainting={setIsPainting}
    option={option} title={title} legendsPosition={legendsPosition} isFullScreen={isFullScreen}
    onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ params, config }) : undefined}
    onHover={onHover ? (params) => onHover({ params, config, point: params?.data?.row }) : undefined}
    onMouseOut={onMouseOut ? (params) => onMouseOut({ params, config }) : undefined}
    getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
    longestY={longestY}
    onFinished={() => {
      setIsPainting(false)
    }} />
};


const ChartLoader = ({ isCalculating, className }) => {
  const { t } = useTranslation()
  const message = isCalculating ? t('general.processingData') : t('general.painting')

  return (
    <div className={className} >
      <Stack className="gap-1 w-full t-heading-m">
        <Icon iconName={isCalculating ? 'WrenchScrewDriver' : 'PaintBrush'} className={'icon-spin'} />
        <p className="">{message}...</p>
      </Stack>
    </div>
  );
};

const DynamicEchartsChartWithLoader = ({ isPainting, isProcessing, setIsPainting, ...echartProps }) => {
  return <div className="relative w-full h-full">
    {(isPainting) &&
      <ChartLoader className={'absolute top-1 left-1  w-full'} />
    }
    {isProcessing && !isPainting &&
      <ChartLoader isCalculating={true} className={'absolute top-1 left-1  w-full'} />
    }
    {!isProcessing &&
      <DynamicEchartsChart {...echartProps}
        onFinished={() => {
          setIsPainting(false)
        }}
      />
    }
  </div >
}


const DynamicHeatmap = ({
  data,
  xDataKey,
  yDataKey,
  chartsConfigs,
  dataTransformator,
  getDataTransformatorMemoDependencyArray,
  config,
  id,
  title,
  getColor,
  getUnit,
  onLegendSelect,
  onHover,
  onMouseOut,
  isItemHovered,
  somethingIsHovered,
  getColumnDisplayName,
  ...props
}) => {
  const dispatch = useDispatch()
  let dataPoint = props.dataPoint

  let { subDataPoint, invertXAxis, invertYAxis, xAxis, yAxis, chartType } = config
  if (subDataPoint)
    dataPoint = subDataPoint

  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({ config })
  let { flattenData, xLabels, yLabels } = useMemo(() => dataTransformator(data, { ...config }), [dataTransformatorDependency, data])

  const { xyzData, xLabelsMemoized, yLabelsMemoized, aggregatedData } = useMemo(() => {
    const aggregatedData = aggregatedSumValue({
      data: flattenData,
      groupByColumns: [xDataKey, yDataKey],
      aggregateColumns: [dataPoint],
    });

    const xyzData = [];
    yLabels.forEach((y, yIndex) => {
      xLabels.forEach((x, xIndex) => {
        const xyRecordIndex = aggregatedData.findIndex(
          (r) => r[xDataKey] === x && r[yDataKey] === y
        );
        const z =
          xyRecordIndex === -1 ? 0 : aggregatedData[xyRecordIndex][dataPoint];
        xyzData.push([xIndex, yIndex, z || 0]);
      });
    });

    return { xyzData, xLabelsMemoized: xLabels, yLabelsMemoized: yLabels, aggregatedData }

  }, [data, dataTransformatorDependency, dataPoint, subDataPoint, xDataKey, yDataKey])

  const defaultColor = "red";
  const color = getColor && getColor({ quantity: dataPoint, chartType, diagrams: chartsConfigs })
  const dataPointUnit = getUnit({ quantity: dataPoint, config })

  const xLabelsMemoizedDisplayName = xLabels?.map(x => getColumnDisplayName({ colName: x }))
  const yLabelsMemoizedDisplayName = yLabels?.map(y => getColumnDisplayName({ colName: y }))


  var option = useMemo(() => {
    if (!isNumeric(Math.min(...(aggregatedData?.map((row) => row[dataPoint]) ?? [])))) return {}

    return {
      legend: {
        show: true,
        itemStyle: {
          color: color ?? defaultColor
        },
        formatter: (name) => getAxisNameWithUnit({ dataKey: getColumnDisplayName({ colName: name }), unit: dataPointUnit }),
        right: 40,
        bottom: 0,
        left: 10,
      },
      tooltip: {
        position: "top",
      },
      grid: {
        right: 70,
        bottom: 60,
        top: 40,
        containLabel: true,
      },
      xAxis: [{
        data: xLabelsMemoizedDisplayName,
        splitArea: {
          show: true,
        },
        name: getColumnDisplayName({ colName: xDataKey }),
        nameLocation: 'center',
        nameGap: 20,
        inverse: invertXAxis,
      }],
      yAxis: [{
        data: yLabelsMemoizedDisplayName,
        splitArea: {
          show: true,
        },
        name: getColumnDisplayName({ colName: yDataKey }),
        inverse: invertYAxis,
      }],
      visualMap: {
        min: Math.min(...(aggregatedData?.map((row) => row[dataPoint]) ?? [])),
        max: Math.max(...(aggregatedData?.map((row) => row[dataPoint]) ?? [])),
        calculable: true,
        orient: "vertical",
        left: "right",
        top: 40,
        inRange: {
          color: ["white", color ?? defaultColor],
        },
      },
      series: [
        {
          name: dataPoint,
          type: "heatmap",
          data: xyzData.map(xyz => {
            const xLabel = xLabelsMemoized[xyz[0]]
            const yLabel = yLabelsMemoized[xyz[1]]
            return {
              value: xyz,
              itemStyle: {
                opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
                  row: { [xDataKey]: xLabel, [yDataKey]: yLabel },
                  xAxis, yAxis, chartType, diagrams: chartsConfigs
                })) ? 0 : 1
              },
              row: { [xDataKey]: xLabel, [yDataKey]: yLabel }
            }
          }),
          label: {
            show: false,
          },
          tooltip: {
            valueFormatter: echartsDefaultTooltipFormatter,
          },
          emphasis: {
            // itemStyle: {
            //   shadowBlur: 10,
            // },
          },
        },
      ],
    };
  }, [somethingIsHovered, aggregatedData, dataPoint, subDataPoint, xAxis, yAxis, invertYAxis, invertXAxis, getColor, config])


  useEffect(() => {
    dispatch(setDataToDownload({ id, data: aggregatedData }))
  }, [id, aggregatedData])

  const longestY = getArrayLongestElement(option.yAxis[0]?.data)
  if (option.series[0]?.data?.length)
    return <DynamicEchartsChart option={option} title={title}
      onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ params, config }) : undefined}
      onHover={onHover ? (params) => onHover({ params, config, point: params?.data?.row }) : undefined}
      onMouseOut={onMouseOut ? (params) => onMouseOut({ params, config }) : undefined}
      getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
      longestY={longestY}
    />;
};

const DynamicHeatmapMultithread = ({
  data,
  xDataKey,
  yDataKey,
  chartsConfigs,
  dataTransformator,
  dataTransformatorMultithread,
  isConfigSetting,
  getDataTransformatorMemoDependencyArray,
  config,
  id,
  title,
  getColor,
  getUnit,
  onLegendSelect,
  onHover,
  onMouseOut,
  isItemHovered,
  somethingIsHovered,
  getColumnDisplayName,
  ...props
}) => {
  const dispatch = useDispatch()
  let dataPoint = props.dataPoint

  let { subDataPoint, invertXAxis, invertYAxis, xAxis, yAxis, chartType } = config
  if (subDataPoint)
    dataPoint = subDataPoint

  const dataTransformatorDependency = getDataTransformatorMemoDependencyArray({ config })

  const [flattenData, setFlattenData] = useState([])
  const [xLabels, setXLabels] = useState([])
  const [yLabels, setYLabels] = useState([])

  const { setIsPainting,
    isProcessing, dataTransformatorDecorator, dataAggregatorDecorator,
    isPainting, option, setOption
  } = useDynamicDashboardCalculations()

  const activeWorkerRef = useRef(null)
  const workerId = useRef(0)
  useEffect(() => {
    if (activeWorkerRef.current?.terminate) {
      activeWorkerRef.current.terminate();
    }

    workerId.current += 1

    const workerInstance = dataTransformatorMultithread()
    activeWorkerRef.current = workerInstance;

    const getTransformedData = async () => {
      const { flattenData: newFlattenData, xLabels, yLabels, requestId } =
        await workerInstance.calculate(data, { ...config }, workerId.current)
      if (requestId === workerId.current) {
        setFlattenData(newFlattenData)
        setXLabels(xLabels)
        setYLabels(yLabels)
      }
    }

    if (!data?.length || isConfigSetting) return
    dataTransformatorDecorator(getTransformedData)

    return () => {
      if (workerInstance.terminate) {
        workerInstance.terminate();
      }
    };

  }, [JSON.stringify(dataTransformatorDependency), data, isConfigSetting])

  const [allAggregatedData, SetAllAggregatedData] = useState([])
  const [xyzData, SetXYZData] = useState([])

  const activeAggregaterWorkerRef = useRef(null)
  const aggregaterWorkerId = useRef(0)
  useEffect(() => {
    if (activeAggregaterWorkerRef.current) {
      activeAggregaterWorkerRef.current.terminate();
    }

    aggregaterWorkerId.current += 1
    const worker = new Worker(new URL('../../../src/heatmapChart.worker.js', import.meta.url));
    activeAggregaterWorkerRef.current = worker;
    const runWorker = async (...props) => {
      const heatmapChartDataAggregater = Comlink.wrap(worker);
      const result = await heatmapChartDataAggregater(...props);
      return result
    }

    const aggregater = async () => {
      let { xyzData, allAggregatedData, requestId } = await runWorker({
        flattenData, dataPoint, xDataKey, yDataKey, xLabels, yLabels
      }, aggregaterWorkerId.current)

      if (requestId === aggregaterWorkerId.current) {
        SetAllAggregatedData(allAggregatedData)
        SetXYZData(xyzData)
      }
    }

    if (!flattenData?.length || isConfigSetting) return
    dataAggregatorDecorator(aggregater, worker)

    return () => {
      worker.terminate();
    }
  },
    [data, dataTransformatorDependency, dataPoint, subDataPoint, xDataKey, flattenData, isConfigSetting]
  )

  const defaultColor = "red";
  const color = getColor && getColor({ quantity: dataPoint, chartType, diagrams: chartsConfigs })
  const dataPointUnit = getUnit({ quantity: dataPoint, config })

  const xLabelsMemoizedDisplayName = xLabels?.map(x => getColumnDisplayName({ colName: x }))
  const yLabelsMemoizedDisplayName = yLabels?.map(y => getColumnDisplayName({ colName: y }))

  useEffect(() => {
    if (isConfigSetting || !flattenData?.length || !xyzData?.length) return

    let newOptions = {}
    if (isNumeric(Math.min(...(allAggregatedData?.map((row) => row[dataPoint]) ?? []))))
      newOptions = {
        legend: {
          show: true,
          itemStyle: {
            color: color ?? defaultColor
          },
          formatter: (name) => getAxisNameWithUnit({ dataKey: getColumnDisplayName({ colName: name }), unit: dataPointUnit }),
          right: 40,
          bottom: 0,
          left: 10,
        },
        tooltip: {
          position: "top",
        },
        grid: {
          right: 70,
          bottom: 60,
          top: 40,
          containLabel: true,
        },
        xAxis: [{
          data: xLabelsMemoizedDisplayName,
          splitArea: {
            show: true,
          },
          name: getColumnDisplayName({ colName: xDataKey }),
          nameLocation: 'center',
          nameGap: 20,
          inverse: invertXAxis,
        }],
        yAxis: [{
          data: yLabelsMemoizedDisplayName,
          splitArea: {
            show: true,
          },
          name: getColumnDisplayName({ colName: yDataKey }),
          inverse: invertYAxis,
        }],
        visualMap: {
          min: Math.min(...(allAggregatedData?.map((row) => row[dataPoint]) ?? [])),
          max: Math.max(...(allAggregatedData?.map((row) => row[dataPoint]) ?? [])),
          calculable: true,
          orient: "vertical",
          left: "right",
          top: 40,
          inRange: {
            color: ["white", color ?? defaultColor],
          },
        },
        series: [
          {
            name: dataPoint,
            type: "heatmap",
            data: xyzData.map(xyz => {
              const xLabel = xLabels[xyz[0]]
              const yLabel = yLabels[xyz[1]]
              return {
                value: xyz,
                itemStyle: {
                  opacity: (somethingIsHovered && isItemHovered && !isItemHovered({
                    row: { [xDataKey]: xLabel, [yDataKey]: yLabel },
                    xAxis, yAxis, chartType, diagrams: chartsConfigs
                  })) ? 0 : 1
                },
                row: { [xDataKey]: xLabel, [yDataKey]: yLabel }
              }
            }),
            label: {
              show: false,
            },
            tooltip: {
              valueFormatter: echartsDefaultTooltipFormatter,
            },
            emphasis: {
              // itemStyle: {
              //   shadowBlur: 10,
              // },
            },
          },
        ],
      }
    setOption({ ...newOptions })
  }, [somethingIsHovered, allAggregatedData, dataPoint, xAxis, yAxis, invertYAxis, , invertXAxis, subDataPoint, getColor, config, isConfigSetting])


  useEffect(() => {
    dispatch(setDataToDownload({ id, data: allAggregatedData }))
  }, [id, allAggregatedData])
  const longestY = getArrayLongestElement(option?.yAxis?.[0]?.data)

  if (isConfigSetting) return
  return <DynamicEchartsChartWithLoader isPainting={isPainting} isProcessing={isProcessing} setIsPainting={setIsPainting}
    option={option} title={title}
    onLegendSelect={onLegendSelect ? (params) => onLegendSelect({ params, config }) : undefined}
    onHover={onHover ? (params) => onHover({ params, config, point: params?.data?.row }) : undefined}
    onMouseOut={onMouseOut ? (params) => onMouseOut({ params, config }) : undefined}
    getLegendDisplayName={(colName) => getColumnDisplayName({ colName })}
    longestY={longestY}
    onFinished={() => {
      setIsPainting(false)
    }} />
};

const DynamicMiniMap = ({
  data,
  dataPoint,
  eventHandlers,
  config,
  id,
  getUnit,
  somethingIsHovered,
  isItemHovered,
  getColumnDisplayName,
  miniMapObjectsNameKey
}) => {
  const dispatch = useDispatch()
  const { xAxis, yAxis, chartType, diagrams } = config
  const colorScale = chroma.scale([
    "#0f0",
    "#ff0",
    "#f00",
  ]);

  const userMinMax = config?.[settingsOptions.COLOR_BAR_RANGE]

  const { dataWithColorAndValueMemoized, min, max } = useMemo(() => {
    const nonNullValues = data?.filter(point => isNumeric(point[dataPoint]))
    const { min: dataMin, max: dataMax } = findDataRange({ data: nonNullValues, dataKeys: [dataPoint] })
    const min = userMinMax?.[0] ?? dataMin
    const max = userMinMax?.[1] ?? dataMax

    const dataWithColorAndValueMemoized = data?.map(point => {
      const opacity = (somethingIsHovered && isItemHovered && !isItemHovered({
        row: point,
        xAxis, yAxis, chartType, diagrams

      })) ? 0 : 1
      return {
        ...point, value: point[dataPoint], center: [point.latitude, point.longitude],
        color: getMapPointColor({ value: point[dataPoint], min, max, colorScale, opacity }),
        name: point[miniMapObjectsNameKey]
      }
    })
    return { dataWithColorAndValueMemoized, min, max }
  }, [data, dataPoint,])

  const colorMin = getMapPointColor({ value: min, min, max })
  const colorMax = getMapPointColor({ value: max, min, max })

  const dataPointUnit = getUnit({ quantity: dataPoint, config })
  const dataPointUnitText = getAxisNameWithUnit({ unit: dataPointUnit })

  const screenshotRef = useRef()

  useEffect(() => {
    const dataToDownload = dataWithColorAndValueMemoized?.map(point => {
      return { center: point.center, [dataPoint]: point.value, color: point.color }
    })
    dispatch(setDataToDownload({ id, data: dataToDownload }))
  }, [id, dataWithColorAndValueMemoized])

  const eventHandlersMemoized = useCallback(eventHandlers(data, config), [JSON.stringify([data, config])])
  return (
    <div className="h-full w-full">
      <Icon className='absolute right-1 top-[2px]  z-10' color={'var(--clr-gray-500)'} size={'md'} iconName={'Download'} onClick={() => {
        html2canvas(screenshotRef.current, {
          useCORS: true,
        }).then((canvasSnapshot) => {
          const screenshot = canvasSnapshot.toDataURL('image/png');
          saveAs(screenshot, `${dataPoint}.png`);
        });
      }} />
      <Stack ref={screenshotRef} flexDirection={'column'} className="w-full h-full relative">
        <div className="h-8 text-center">
          <span className="t-heading-l mb-1">{getColumnDisplayName({ colName: dataPoint }) + dataPointUnitText}</span>
        </div>

        <div className="w-full flex-1 relative">
          {/* Pass key to the map for force rezooming when only data gets updated */}
          <MyMap
            key={data}
            resizeTriggerVariables={JSON.stringify([config.w, config.h])}
            rerenderVariable={JSON.stringify([data, dataPoint])}
            data={dataWithColorAndValueMemoized}
            eventHandlers={eventHandlersMemoized}
          />
          <ColorBar min={min} max={max} colorMin={colorMin} colorMax={colorMax}
            className=" absolute right-2 z-[999]"
          />
        </div>
      </Stack>
    </div>

  )
}

const ChartType = ({ chartType, xDataKey = "", multithread, ...props }) => {

  if (!props.chartsConfigs?.length) return;
  if (chartType === chartTypesEnums.COMPOSED) {
    if (!props?.data?.length || !xDataKey) return;
    return multithread ? <DynamicComboChartMultithread {...{ xDataKey, ...props }} /> : <DynamicComboChart {...{ xDataKey, ...props }} />;
  }

  if (chartType === chartTypesEnums.HISTOGRAM) {
    if (!props?.chartsConfigs?.filter((k) => k.dataKey)?.length) return;
    return <DynamicHistogramChart {...{ ...props }} />;
  }

  if ([chartTypesEnums.PIE, chartTypesEnums.DOUGHNUT].includes(chartType)) {
    if (!props?.chartsConfigs.length) return;
    return multithread ? <DynamicPieChartMultithread {...{ ...props, chartType }} /> : <DynamicPieChart {...{ ...props, chartType }} />;
  }

  if ([chartTypesEnums.BAR, chartTypesEnums.AREA, chartTypesEnums.LINE].includes(chartType))
    return multithread ? <DynamicStackedChartMultithread {...{ ...props, chartType, xDataKey }} /> : <DynamicStackedChart {...{ ...props, chartType, xDataKey }} />

  if (chartType === chartTypesEnums.KPI)
    return multithread ? <DynamicKPIMultithread {...{ ...props, chartType, xDataKey }} /> : <DynamicKPI {...{ ...props, chartType, xDataKey }} />;

  //TODO: dataPoint change doesn't rerender the heatmap? Why error?
  if (chartType === chartTypesEnums.HEATMAP && props.dataPoint) {
    if (!props?.dataPoint || !xDataKey || !props?.yDataKey) return;
    return multithread ? <DynamicHeatmapMultithread {...{ xDataKey, ...props }} /> : <DynamicHeatmap {...{ xDataKey, ...props }} />;
  }
  if (chartType === chartTypesEnums.MINI_MAP && props.dataPoint) {
    return <DynamicMiniMap {...{ xDataKey, ...props }} />;
  }
};


const colorGroups = [
  "secondary-blue",
  "mystic-red",
  "vivid-orchid",
  "plunge",
  "star",
  "gray",
  "leaftech-blue",
  "tangerine",
  "cathode-green",
  "bright-indigo",
];

const spectrum = [600, 400, 200, 800];
let flatColors = [];
spectrum.forEach((s) => {
  colorGroups.forEach((group) => {
    flatColors.push(`--clr-${group}-${s}`);
  });
});

const stackedColors = spectrum.map((s) => {
  return colorGroups.map((group) => `--clr-${group}-${s}`);
});

const getItemColor = (stackIdx, partitionIdx) => {
  var style = getComputedStyle(document.documentElement);
  const isStacked = stackIdx >= 0;
  const isPartitioned = partitionIdx >= 0;

  if (!isStacked && !isPartitioned) {
    return style.getPropertyValue(flatColors[0]).trim();
  }
  if (!(isStacked && isPartitioned)) {
    return style
      .getPropertyValue(
        flatColors[(stackIdx ?? partitionIdx) % flatColors.length]
      )
      .trim();
  }
  const validStackIdx = stackIdx % stackedColors.length;
  const validPartitionIdx = partitionIdx % stackedColors[0].length;

  return style.getPropertyValue(
    stackedColors[validStackIdx][validPartitionIdx]
  );
};



const DynamicChartSettings = ({
  diagrams,
  setDiagrams,
  selectedChartType,
  settings
}) => {

  const handleAddDiagram = () => {
    setDiagrams([...diagrams, diagramInitialConfig]);
  };

  const diagramsSettings = settings?.filter((setting, index) => setting.isInDiagramsPart)
  const otherSettings = settings?.filter((setting, _index) => !setting.isInDiagramsPart)
  const hasMultipleDiagrams = [chartTypesEnums.COMPOSED, chartTypesEnums.HISTOGRAM].includes(selectedChartType)

  return (
    <Stack direction={"column"} className="w-full mt-4 gap-2 ">
      {otherSettings?.map(setting => {
        if (!setting.visible()) return
        const { filterType, ...args } = setting
        const filterFactory = new DynamicFilterFactory(filterType, args)
        const filterInstance = filterFactory.createFilterClassInstance()
        if (filterInstance) {
          const FilterComponent = filterInstance.createComponent()
          return <Stack className="w-full" justifyContent={"space-between"}>
            <span className="block w-1/3 t-heading-m">{setting.filterLabel} :</span>
            {FilterComponent}
          </Stack>
        }
      })}

      {diagrams?.map((diagram, index) => {
        return (
          <>
            {hasMultipleDiagrams && (
              <Stack gap={2} className="items-center mt-1">
                <Icon
                  iconName={"Close"}
                  size={"sm"}
                  color={"red"}
                  onClick={() =>
                    setDiagrams(diagrams.filter((_d, i) => i !== index))
                  }
                />
                <span className="block t-heading-m">Diagram {index + 1}:</span>
              </Stack>
            )}
            {diagramsSettings?.map(setting => {
              if (!setting.visible(index)) return
              setting.config = diagram
              setting.setConfig = setting.setConfigFn(index)

              const { filterType, ...args } = setting
              const filterFactory = new DynamicFilterFactory(filterType, args)
              const filterInstance = filterFactory.createFilterClassInstance()
              if (filterInstance) {
                const FilterComponent = filterInstance.createComponent()
                return <Stack className="w-full" justifyContent={"space-between"}>
                  <span className="block w-1/3 t-heading-m">{setting.filterLabel} :</span>
                  {FilterComponent}
                </Stack>
              }
            })}
          </>
        );
      })}

      {hasMultipleDiagrams && (
        <ButtonNew
          onClick={handleAddDiagram}
          variant="primary"
          size="sm"
          className="mt-1"
        >
          + Add Diagram
        </ButtonNew>
      )}

    </Stack>

  );
};

const ChartFilters = ({ filters }) => {
  return (
    <Stack flexDirection={"column"} gap={2}>
      {filters?.map(filter => {
        if (!filter.visible()) return
        const { filterType, ...args } = filter
        const filterFactory = new DynamicFilterFactory(filterType, args)
        const filterInstance = filterFactory.createFilterClassInstance()
        if (filterInstance) {
          const FilterComponent = filterInstance.createComponent()
          return FilterComponent
        }
      })}
    </Stack>
  );
};



const ChartNavbar = ({
  id,
  onClose,
  isVisible,
  setIsVisible,
  handleClick,
  setChartSelectionIsOpen,
  isFullScreen,
  enterFullScreenMode,
  exitFullScreenMode,
  hasFilters
}) => {
  const configsIconsColor = "var(--clr-secondary-blue-500)";
  const items = [
    {
      onClick: onClose,
      iconName: "Trash",
      color: 'var(--clr-mystic-red-500)'
    },
    {
      iconName: isFullScreen ? "FullscreenExit" : "Fullscreen",
      onClick: isFullScreen ? exitFullScreenMode : enterFullScreenMode,
      stroke: true
    },
    {
      iconName: isVisible ? "EyeClose" : "EyeOpen",
      onClick: () => setIsVisible(!isVisible),
    },
    {
      onClick: () => handleClick("settings"),
      iconName: "Settings",
    },
    {
      onClick: () => handleClick("filters"),
      iconName: "Filter",
      inactive: !hasFilters
    },
    {
      iconName: "Piechart",
      onClick: (e) => {
        setChartSelectionIsOpen(
          (chartSelectionIsOpen) => !chartSelectionIsOpen
        );
      },
    },
  ];

  const dataToDownload = useSelector(store => store.energyPerformanceNew).dataToDownload?.[id]
  return (
    <Stack
      className="px-2 h-6 gap-4 w-full bg-blue-50  rounded items-center cursor-grab "
      flexDirection={"row-reverse"}
    >
      {items?.map((item) => {
        if (!item.inactive)
          return (
            <Icon
              className="cancelDrag  cursor-pointer "
              onClick={item.onClick}
              iconName={item.iconName}
              svgClassName="w-4 h-4 hover:scale-[1.3] transition"
              color={item.color || configsIconsColor}
              stroke={item.stroke && configsIconsColor}
            />
          )
      })}
      <EmptyErrorBoundary>
        {dataToDownload &&
          <CSVLink data={dataToDownload} >
            <Icon
              className="cancelDrag  cursor-pointer"
              iconName={"Download"}
              svgClassName="hover:scale-[2] transition"
              size='md'
              color={configsIconsColor}
            />
          </CSVLink>
        }
      </EmptyErrorBoundary>


    </Stack>
  );
};

const DynamicChartNavbarAndSidebar = forwardRef((props, ref) => {
  const {
    onClose,
    setIsVisible,
    isVisible,
    setChartSelectionIsOpen,
    diagrams,
    chartType,
    xCoord,
    isFullScreen,
    setFullScreenChartId,
    id,
    filters,
    settings,
    setDiagrams,
    setIsConfigSetting,
    isConfigSetting,
    multithread
  } = props;

  const { t } = useTranslation()

  const [openSidebar, setOpenSidebar] = useState(false);
  const [section, setSection] = useState(null);
  const handleClick = (selectedSection) => {
    setSection(selectedSection);
    setOpenSidebar(true);
    // setIsConfigSetting(true)
  };

  useImperativeHandle(ref, () => ({
    childHandleClick: handleClick,
  }));

  const hasFilters = filters?.length > 0
  return (
    <>
      <ChartNavbar
        id={id}
        onClose={() => {
          if (onClose)
            onClose()
        }}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        handleClick={handleClick}
        setChartSelectionIsOpen={setChartSelectionIsOpen}
        isFullScreen={isFullScreen}
        enterFullScreenMode={() => setFullScreenChartId(id)}
        exitFullScreenMode={() => setFullScreenChartId(null)}
        hasFilters={hasFilters}
      />
      {openSidebar &&
        <Modal open={openSidebar} onClose={() => {
          setOpenSidebar(false)
          // setIsConfigSetting(false)
        }}
          disable
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: '#00000050',
              },
            },
          }}
        >
          <Paper
            className={`cancelDrag fixed z-[6] rounded top-8 bottom-8 w-[60rem] overflow-y-auto ${xCoord < 6 ? "right-8" : "left-8"
              }`}
            style={{
              backdropFilter: "blur(10px)",
              background: "rgba(255,255,255,0.6)",
            }}
          >
            {multithread &&
              <Stack className="justify-between mb-4">
                <div className=" t-heading-m">
                  {/* <span>{t('EnergyPerformance.dashboard.liveCalculationMode')}</span> */}
                  {/* <Switch checked={isLiveMode} onChange={(e) => setIsLiveMode(e.target.checked)} /> */}
                  <ButtonNew className={!isConfigSetting && '!bg-red-500'} variant={'primary'} disabled={isConfigSetting} size={'md'} onClick={() => {
                    setIsConfigSetting(true)
                    // setIsLiveMode(false)
                  }}>
                    <Stack gap={2} className="capitalize">
                      <Icon iconName={'Pause'} size={'md'} />
                      {t('EnergyPerformance.dashboard.pauseInstantCalculation')}
                    </Stack>
                  </ButtonNew>
                </div>

                <ButtonNew className={isConfigSetting && '!bg-green-500'} variant={'primary'} disabled={!isConfigSetting} size={'md'} onClick={() => {
                  setIsConfigSetting(false)
                  setOpenSidebar(false)
                  // setIsLiveMode(true)
                }}>
                  <Stack gap={2}>
                    <Icon iconName={'Play'} size={'md'} />
                    {t('general.run')}
                  </Stack>
                </ButtonNew>
              </Stack>
            }
            {section === "settings" && (<DynamicChartSettings diagrams={diagrams} setDiagrams={setDiagrams} selectedChartType={chartType} settings={settings} />)}
            {section === "filters" && (<ChartFilters filters={filters} />)}
          </Paper>
        </Modal>
      }
    </>
  );
});

const DynamicChartBody = ({
  isLoading,
  isError,
  generalData,
  specificData,
  width,
  chartType,
  dataPoint,
  title,
  xAxis,
  yAxis,
  diagrams,
  sortValue,
  height,
  legendsPosition,
  id,
  filters,
  settings,
  dataTransformator,
  dataTransformatorMultithread,
  getDataTransformatorMemoDependencyArray,
  eventHandlers,
  config,
  getUnit,
  getColor,
  onLegendSelect,
  onHover,
  onMouseOut,
  isItemHovered,
  somethingIsHovered,
  getColumnDisplayName,
  getColumnSortFunction,
  hoverEffect,
  isConfigSetting,
  multithread,
  miniMapObjectsNameKey
}) => {
  const { t } = useTranslation()
  const data = specificData?.length ? specificData : generalData;

  const [filteredData, setFilteredData] = useState(data);

  const triggerFitleringDependency = JSON.stringify([filters?.filter(f => f.mainDataKey)?.map(f => f?.config[f?.filterName]),
  settings?.filter(s => s.mainDataKey && s.useAsDataFilter)?.map(s => s?.config[s?.filterName])])

  const piechartDependency = JSON.stringify(config.chartType === chartTypesEnums.PIE && config)

  useEffect(() => {
    if (!isLoading && !isConfigSetting) {
      const unsortedData = [...data];
      let partiallyFilteredData = unsortedData
      filters.forEach(filter => {
        const { filterType, mainDataKey, ...args } = filter
        if (!mainDataKey) return
        const filterFactory = new DynamicFilterFactory(filterType, args)
        const filterInstance = filterFactory.createFilterClassInstance()
        partiallyFilteredData = filterInstance.filterData(partiallyFilteredData, mainDataKey)
      })
      settings.forEach(setting => {
        const { filterType, mainDataKey, useAsDataFilter, ...args } = setting
        if (!mainDataKey || !useAsDataFilter) return
        const filterFactory = new DynamicFilterFactory(filterType, args)
        const filterInstance = filterFactory.createFilterClassInstance()
        partiallyFilteredData = filterInstance.filterData(partiallyFilteredData, mainDataKey)
      })

      setFilteredData(partiallyFilteredData)
    }
  }, [generalData, isLoading, specificData, triggerFitleringDependency, config?.w, config?.h, piechartDependency, isConfigSetting]);


  return (
    <div className={`relative h-full`}>
      <LoadingOrEmptyWrapper
        showLoading={isLoading}
        showEmpty={!data?.length || isError}
        height="100%"
        loadingText={t('general.loadingDataPleaseWait')}
      >
        <ResponsiveContainer width={"100%"} height={"100%"}>

          {/* <Profiler id={chartType + Math.random()} onRender={(id, phase, actualDuration) => {
            console.log(chartType, actualDuration);
          }}> */}
          <ChartType
            config={config}
            chartType={chartType}
            dataPoint={dataPoint}
            title={title}
            xDataKey={xAxis}
            yDataKey={yAxis}
            id={id}
            data={filteredData}
            chartsConfigs={diagrams}
            sort={sortValue}
            sortBy={xAxis === "index" ? sortValue : xAxis}
            width={width}
            height={height}
            legendsPosition={legendsPosition}
            dataTransformator={dataTransformator}
            dataTransformatorMultithread={dataTransformatorMultithread}
            getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
            eventHandlers={eventHandlers}
            getUnit={getUnit}
            getColor={getColor}
            onLegendSelect={onLegendSelect}
            onHover={onHover}
            onMouseOut={onMouseOut}
            isItemHovered={isItemHovered}
            somethingIsHovered={somethingIsHovered}
            getColumnDisplayName={getColumnDisplayName}
            getColumnSortFunction={getColumnSortFunction}
            hoverEffect={hoverEffect}
            isConfigSetting={isConfigSetting}
            multithread={multithread}
            miniMapObjectsNameKey={miniMapObjectsNameKey}
          />
          {/* </Profiler> */}
        </ResponsiveContainer>
      </LoadingOrEmptyWrapper>
    </div>
  );
}

const ChartTypeSelection = ({ chartOptions, handleChartSelection }) => {
  return (
    <Grid
      container
      spacing={2}
      className="w-full h-full mt-1"
      justifyContent={"space-evenly"}
    >
      {chartOptions?.map((option, index) => {
        const IconComponent = option.icon;
        return (
          <Grid item xs={3} className="hover:scale-[1.2] transition" key={index}>
            <Stack className="justify-center items-center">
              <Stack
                direction={"column"}
                className="hover:bg-blue-100 w-15 h-15 p-2 bg-[#f5f5ff] rounded-xl cursor-pointer "
                onClick={() => {
                  handleChartSelection(option.type);
                }}
              >
                <IconComponent size={32} color={option.color} />
                <span className="t-label-m">{option.label} </span>
              </Stack>
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};


// The userLanguage parameter is used only to make a rerender when changed.
// TODO: Use a better approach
const DynamicChartMemoized = ({
  id,
  generalData,
  onClose,
  height,
  xCoord,
  config,
  setConfig,
  setFullScreenChartId,
  isFullScreen,
  userLanguage,
  filters,
  settings,
  chartTypes,
  specificDataGetter,
  dataTransformator,
  dataTransformatorMultithread,
  getDataTransformatorMemoDependencyArray,
  eventHandlers,
  getUnit,
  getColor,
  onLegendSelect,
  onHover,
  onMouseOut,
  isItemHovered,
  somethingIsHovered,
  getColumnDisplayName,
  getColumnSortFunction,
  hoverEffect,
  multithread,
  miniMapObjectsNameKey
}) => {
  console.log(id)
  // console.log('DynamicChartMemoized')

  // const dispatch = useDispatch()

  // const config = useSelector(store => store.dynamicDashboard.Portfolio?.allConfigs?.[id], shallowEqual)

  // const setConfig = useCallback((key, value) => dispatch(setSingleConfig({ id, config: { ...config, [key]: value }, appName: 'Portfolio' }))
  //   , [config])
  const {
    diagrams,
    sortValue,
    chartType,
    title,
    xAxis,
    yAxis,
    dataPoint,
    legendsPosition,
  } = config;

  const setChartType = (newValue) => setConfig("chartType", newValue);

  const { specificData, isLoading, isError } = specificDataGetter(config)
  const [chartSelectionIsOpen, setChartSelectionIsOpen] = useState(!chartType);

  const navbarAndSidebarRef = useRef();
  const handleChartSelection = (type) => {
    setChartType(type);
    setChartSelectionIsOpen(false);
    navbarAndSidebarRef.current?.childHandleClick("settings");
  };

  const color = "var(--clr-secondary-blue-400)";
  const allAvailableChartOptions = [
    {
      label: "Area",
      icon: LiaChartAreaSolid,
      type: chartTypesEnums.AREA,
      color,
    },
    {
      label: "Doughnut",
      icon: PiChartDonutThin,
      type: chartTypesEnums.DOUGHNUT,
      color,
    },
    {
      label: "Pie",
      icon: IoPieChartOutline,
      type: chartTypesEnums.PIE,
      color,
    },
    {
      label: "Histogram",
      icon: GiHistogram,
      type: chartTypesEnums.HISTOGRAM,
      color,
    },
    {
      label: "Combo",
      icon: TbChartHistogram,
      type: chartTypesEnums.COMPOSED,
      color,
    },
    {
      label: "Bar",
      icon: IoBarChartOutline,
      type: chartTypesEnums.BAR,
      color,
    },
    {
      label: "Line",
      icon: PiChartLineLight,
      type: chartTypesEnums.LINE,
      color,
    },
    {
      label: "KPI",
      icon: TbSum,
      type: chartTypesEnums.KPI,
      color,
    },
    {
      label: "Heatmap",
      icon: TfiLayoutGrid4Alt,
      type: chartTypesEnums.HEATMAP,
      color,
    },
    {
      label: "Map",
      icon: FaMapLocationDot,
      type: chartTypesEnums.MINI_MAP,
      color,
    },
  ];

  const chartOptions = allAvailableChartOptions.filter(chartOption => !chartTypes || (chartTypes?.includes(chartOption.type)))

  const [isVisible, setIsVisible] = useState(true);

  const setDiagrams = (newValue) => setConfig("diagrams", newValue);

  const [isConfigSetting, setIsConfigSetting] = useState(false)


  return (
    <>
      <Paper className={`p-4 h-full`}>
        <div className={`h-full w-full ${chartSelectionIsOpen && "bg-white"}`}>
          <DynamicChartNavbarAndSidebar
            ref={navbarAndSidebarRef}
            setIsVisible={setIsVisible}
            isVisible={isVisible}
            setChartSelectionIsOpen={setChartSelectionIsOpen}
            onClose={onClose}
            diagrams={diagrams}
            chartType={chartType}
            xCoord={xCoord}
            isFullScreen={isFullScreen}
            setFullScreenChartId={setFullScreenChartId}
            id={id}
            filters={filters}
            settings={settings}
            setDiagrams={setDiagrams}
            isConfigSetting={isConfigSetting}
            setIsConfigSetting={setIsConfigSetting}
            multithread={multithread}
          />
          <TextErrorBoundary>
            {isVisible && (
              <div
                style={{ height: "calc(100% - 24px)" }}
                className=" cancelDrag"
              >
                {chartSelectionIsOpen && (
                  <ChartTypeSelection
                    chartOptions={chartOptions}
                    handleChartSelection={handleChartSelection}
                  />
                )}

                {!chartSelectionIsOpen && !isConfigSetting && (
                  <DynamicChartBody
                    isLoading={isLoading}
                    isError={isError}
                    generalData={generalData}
                    specificData={specificData}
                    chartType={chartType}
                    dataPoint={dataPoint}
                    title={title}
                    xAxis={xAxis}
                    yAxis={yAxis}
                    diagrams={diagrams}
                    sortValue={sortValue}
                    width={"100%"}
                    height={height}
                    legendsPosition={legendsPosition}
                    id={id}
                    filters={filters}
                    settings={settings}
                    dataTransformator={dataTransformator}
                    dataTransformatorMultithread={dataTransformatorMultithread}
                    getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
                    eventHandlers={eventHandlers}
                    config={config}
                    getUnit={getUnit}
                    getColor={getColor}
                    onLegendSelect={onLegendSelect}
                    onHover={onHover}
                    onMouseOut={onMouseOut}
                    isItemHovered={isItemHovered}
                    somethingIsHovered={somethingIsHovered}
                    getColumnDisplayName={getColumnDisplayName}
                    getColumnSortFunction={getColumnSortFunction}
                    hoverEffect={hoverEffect}
                    // isConfigSetting={isConfigSetting && !isLiveMode}
                    isConfigSetting={isConfigSetting}
                    multithread={multithread}
                    miniMapObjectsNameKey={miniMapObjectsNameKey}

                  />
                )}
              </div>
            )}
          </TextErrorBoundary>
        </div>
      </Paper>
    </>
  );
};

export default React.memo(DynamicChartMemoized, isEqual);
