import React from "react";
import { addCommaToNumber } from "../../utils/namesManipulation";

export const Capex = ({ capex, className }) => {
  return (
    <div className={className}>
      <h4 className=" t-label-m-caps uppercase text-gray-500">capex</h4>
      <p className="t-numbers-xxl  text-blue-500">
        {addCommaToNumber(capex)}
        <span className="t-heading-l  text-blue-500">€</span>
      </p>
    </div>
  );
};
