export default {
  bulletChart: {
    energyTitle: "Energy performance indicator",
    carbonTitle: "Carbon performance indicator",
    "Target value": "Target value",
    "Status quo": "Status quo",
    "Net-Zero emission": "Net-Zero emission",
    "net zero": "net zero",
    "Low tax zone": "Low tax zone",
    "High tax zone": "High tax zone",
  },
  table: {
    title: "Energy mix of building",
    downloadText: "Download information",
    columns: {
      "Utility mix": "Utility mix",
      Type: "Type",
      Source: "Source",
      Price: "Price",
      "CO₂ emission": "CO₂ emission",
    },
  },
  dashboard: {
    title: "Performance dashboard",
    downloadText: "Download information",
    simulated: "simulated",
    measured: "measured",
    totalConsumption: "total cosumption",
    totalEmission: "total emission",
    totalPrice: "total price",
    total: "total",
    Total: "Total",
    dataDropDownLabel: "select data points",
    Consumption: "Consumption",
    Price: "Price",
    "CO₂ emission": "CO₂ emission",
    "Electricity-Simulated": "Electricity-Simulated",
    "Hot water system-Simulated": "Hot water system-Simulated",
    "Heating system-Simulated": "Heating system-Simulated",
    "Cooling system-Simulated": "Cooling system-Simulated",
    "Electricity-Measured": "Electricity-Measured",
    "Hot water system-Measured": "Hot water system-Measured",
    "Heating system-Measured": "Heating system-Measured",
    "Cooling system-Measured": "Cooling system-Measured",
    Electricity: "Electricity",
    "Hot water system": "Hot water system",
    "Heating system": "Heating system",
    "Cooling system": "Cooling system",
    addNewChart: "Add new chart",
    deleteChart: "Delete chart",
    cancel: "Cancel",
    confirmSelection: "Confirm selection",
    datesMustBeSelected: "Start date and end date must be selected.",
    areaIsMissing: "Building area is missing so the numbers are not valid.",
    getDataProblem: "There was a problem getting data!",
    noData: "There is nothing to show!",

    liveCalculationMode: 'Live Calculation Mode',
    pauseInstantCalculation: 'pause instant calculation'
  },
};
