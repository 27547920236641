import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  BuildingProfile,
  Certificates,
  EnergyPerformance,
  Modernization,
  TechnicalData,
  AssetOverview,
  BuildingPassportSharedLayout,
  Login,
  ProtectedRoute,
  SharedLayout,
  Administration,
} from "./pages";

import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { theme } from "../src/styles/theme";
import { store } from "./store";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import "./App.css";
import "./styles/rsuitjsCustom.css";
import { EnergyPerformanceNew } from "./pages/EnergyPerformanceNew";
import { Portfolio } from "./pages/Portfolio";
import { LandingPageSharedLayout } from "./pages/LandingPageSharedLayout";
import { PortfolioNew } from "./pages/PortfolioNew";
import { EnergyPerformanceNewMultiTab } from "./pages/EnergyPerformanceNewMultiTab";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 10000000, // about 3 hours
      // cacheTime: 0,
      staleTime: 10000000,
      retry: false,
    },
  },
});
function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Routes>
                <Route path="login" element={<Login />} />
                <Route path="privacyPolicy" element={<PrivacyPolicy />} />
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <SharedLayout />
                    </ProtectedRoute>
                  }
                >
                  <Route path="" element={<LandingPageSharedLayout />}>
                    <Route path="" element={<AssetOverview />} />
                    <Route path="portfolio" element={<PortfolioNew />} />
                    {/* <Route path="portfolio" element={<Portfolio />} /> */}


                  </Route>
                  <Route
                    path="administration"
                    element={<Administration></Administration>}
                  />
                  <Route
                    path="/details/:projectId/"
                    element={<BuildingPassportSharedLayout />}
                  >
                    <Route
                      index
                      path="buildingProfile"
                      element={<BuildingProfile />}
                    />
                    <Route
                      path="energyPerformance2"
                      element={<EnergyPerformance />}
                    // element={<EnergyPerformanceNew />}
                    />
                    <Route
                      path="energyPerformance"
                      element={<EnergyPerformanceNewMultiTab />}
                    />
                    <Route path="technicalData" element={<TechnicalData />} />
                    {/* <Route path="certificates" element={<Certificates />} /> */}
                    <Route path="modernization" element={<Modernization />} />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
            <ToastContainer />
          </QueryClientProvider>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider >
  );
}

export default App;
