import { Stack } from "@mui/material";
import React from "react";


export const LabelValueInline = ({ label, value, className, labelProps, valueProps }) => {
  const { className: valueClassName, ...otherValueProps } = valueProps
  const { className: labelClassName, ...otherLabelProps } = valueProps
  return (
    <Stack className={`mb-1 t-body-s items-start ${className}`}>
      <div className={`text-gray-600 max-w-[33rem]  min-w-[12rem] ${labelClassName}`}  {...otherLabelProps} >
        {label}:
      </div>
      <div className={`ml-1   ${valueClassName}`} {...otherValueProps}  >
        {` ${value}`}
      </div>
    </Stack>

  );
};
